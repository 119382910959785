import React, {
  useMemo,
} from "react";
import { useTranslation } from "react-i18next";
import { Button, Dialog, Text } from "tarmac-v3";

const TagsDeleteDialog = (props) => {
  const {
    open, setOpen, deleteTag,
  } = props;
  const { t } = useTranslation();

  const DialogActions = useMemo(() => (
    <>
      <Button
        variant="text"
        color="secondary"
        label={t("Annuler")}
        onClick={() => setOpen(false)}
      />

      <Button
        variant="contained"
        color="error"
        label={t("Supprimer le tag")}
        onClick={deleteTag}
      />
    </>
  ), [t, setOpen, deleteTag]);

  return (
    <Dialog
      open={open}
      title={t("Supprimer le tag")}
      Actions={DialogActions}
      onClose={() => setOpen(false)}
      sx={{ zIndex: 1400 }}
    >
      <Text variant="body1">
        <>
          {t("Cette action va supprimer le Tag et ainsi l'enlever aux appareils attribués.")}
          <br />
          {t("Souhaitez-vous poursuivre ?")}
        </>
      </Text>
    </Dialog>
  );
};

export default TagsDeleteDialog;

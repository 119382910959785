import {
  faFileContract, faGauge, faGear, faHammerCrash, faLaptopSlash, faPenToSquare, faQuestionCircle, faReceipt, faRecycle, faShieldCheck, faTruck,
} from "@fortawesome/pro-regular-svg-icons";
import { theme } from "tarmac-v3";

const getTicketCategory = (ticket) => {
  const ticketCategory = ticket?.category;

  const categoryLabels = [
    { value: "ORDER__UPDATE", label: "Modification commande", icon: faPenToSquare },
    { value: "ORDER__SHIPPING", label: "Adresse et livraison", icon: faTruck },
    { value: "CONTRACT__INFORMATION", label: "Contrats en cours", icon: faFileContract },
    { value: "CONTRACT__RENEWAL", label: "Retour et fin de contract", icon: faRecycle },
    { value: "CONTRACT__INVOICE", label: "Factures et prélèvements", icon: faReceipt },
    { value: "INSURANCE__LOST", label: "Assurance vol", icon: faLaptopSlash },
    { value: "INSURANCE__BROKEN", label: "Assurance casse", icon: faHammerCrash },
    { value: "TECHNICAL_PROBLEM", label: "Problème technique", icon: faGear },
    { value: "MDM", label: "Mobile Device Management", icon: faShieldCheck },
    { value: "COCKPIT", label: "Usage Cockpit", icon: faGauge },
  ];

  const category = categoryLabels.find((cl) => cl.value === ticketCategory) || { label: "Question sur ma commande", icon: faQuestionCircle };

  return category;
};

const getTicketStatus = (status) => {
  if (status === "WAITING_CLIENT") {
    return {
      label: "Action requise",
      color: "warning",
      iconColor: "warning",
    };
  }

  if (status === "CLOSED" || status === "SOLVED" || status === "ARCHIVED") {
    return {
      label: "Résolu",
      color: "secondary",
      iconColor: theme.palette.text.secondary,
    };
  }

  return {
    label: "En attente",
    color: "pending",
    iconColor: "pending",
  };
};

const applyBasicFilters = (tickets, filters, field) => tickets.filter((ticket) => {
  if (filters[field].length === 0) {
    return true;
  }

  if (field === "createdBy") {
    return filters[field].includes(ticket.requester_id);
  }

  return filters[field].includes(ticket[field]);
});

const applyStatusFilters = (tickets, filters) => tickets.filter((ticket) => {
  if (filters.status.length === 0) {
    return true;
  }

  if (filters.status.includes("WAITING") && ["OPENED", "WAITING_FLEET", "WAITING_SUPPLIER", "WAITING_DEVICE_RETURN", "IN_REPAIR", "SHIPPING", "RETURNING_REPLACEMENT"].includes(ticket.status)) {
    return true;
  }

  if (filters.status.includes("ACTION_REQUESTED") && ["WAITING_CLIENT"].includes(ticket.status)) {
    return true;
  }

  if (filters.status.includes("RESOLVED") && ["CLOSED", "SOLVED", "ARCHIVED", "CLOSING"].includes(ticket.status)) {
    return true;
  }

  return false;
});

export {
  getTicketCategory, getTicketStatus, applyBasicFilters, applyStatusFilters,
};

import React from "react";

const CockpitOrderContext = React.createContext({
  order: null,
  resetOrder: () => {},
  fetchOrder: () => {},
  reloadOrder: () => {},
  updateOrder: () => {},
});

export default CockpitOrderContext;

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Alert, Button, Checkbox, Heading, HorizontalLayout, HorizontalSeparator, Label, LabeledTextField, Modal, Select, Text, VerticalLayout,
} from "tarmac-v2";

import PhoneInput from "src/components/utils/PhoneInput";
import { useUserContext } from "src/contexts/UserContext";
import { useToastContext } from "src/contexts/ToastContext";
import CompanyAddressesSuggestions from "./CompanyAddressesSuggestions";

const CompanyAddressesModal = (props) => {
  // PROPS & CONTEXT
  const { onSave, address, onClose } = props;
  const { user: currentUser } = useUserContext();
  const { addToast } = useToastContext();

  const { t } = useTranslation();

  // GENERAL STATES
  const [errors, setErrors] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  // INITIALISATION STATES & VARIABLES
  const initialAddress = {
    label: address?.label || "",
    address1: address?.address1 || "",
    address2: address?.address2 || "",
    city: address?.city || "",
    zip: address?.zip || "",
    country: address?.country || "",
    type: address?.type || "OFFICE",
    firstName: address ? address.firstName : currentUser.firstName || "",
    lastName: address ? address.lastName : currentUser.lastName || "",
    phone: address ? address.phone : currentUser.phone || null,
    phone_country: address ? address.phone_country : currentUser.phone_country || null,
    comment: address?.comment || "",
    is_default: address?.is_default,
  };
  const [addressInfo, setAddressInfo] = useState(initialAddress);
  const [isLoading, setIsLoading] = useState(false);

  // Close modal (cancel)
  const onCloseAddressModal = () => {
    setErrors("");
    onClose();
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;

    setAddressInfo((prevInfo) => ({ ...prevInfo, [name]: value }));
  };

  const handlePhoneChange = (phone, countryPhone) => {
    setAddressInfo((prevInfo) => ({ ...prevInfo, phone, phone_country: countryPhone }));
  };

  const handleSelectCountry = (e) => {
    const selectedCountry = e.label;

    setAddressInfo((prevInfo) => ({ ...prevInfo, country: selectedCountry }));
  };

  // Check if modal button should be disabled
  const buttonDisabled = () => {
    if (
      addressInfo.label?.trim() === ""
      || addressInfo.address1?.trim() === ""
      || addressInfo.zip?.trim() === ""
      || addressInfo.city?.trim() === ""
      || addressInfo.country?.trim() === ""
      || addressInfo.lastName?.trim() === ""
      || addressInfo.firstName?.trim() === ""
      || addressInfo.phone?.trim() === ""
      || !addressInfo.phone_country
      || !addressInfo.type
    ) {
      return {
        message: t("Merci de remplir tous les champs obligatoires (*)"),
      };
    }

    return "";
  };

  // Create a new address or update it
  const saveChanges = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const addressConfig = {
        address1: addressInfo.address1,
        address2: addressInfo.address2,
        city: addressInfo.city,
        zip: addressInfo.zip,
        country: addressInfo.country,
        type: addressInfo.type,
        label: addressInfo.label,
        firstName: addressInfo.firstName,
        lastName: addressInfo.lastName,
        phone: addressInfo.phone,
        phone_country: addressInfo.phone_country,
        comment: addressInfo.comment,
        is_default: addressInfo.is_default,
      };

      if (addressInfo.phone && !isPhoneValid) {
        addToast(t("Le numéro de téléphone n'est pas valide"));
        setIsLoading(false);

        return;
      }

      if (address) {
        // MODIFY ADDRESS FROM COCKPIT
        await currentUser.api.modifyAddress(address.id, addressConfig);
        addToast(t("Adresse {{ addressName }} modifiée avec succès", { addressName: addressConfig.label }), "success");
        onSave();
        setIsLoading(false);
      } else {
        // ADD ADDRESS FROM COCKPIT
        const newAddress = await currentUser.api.addAddress(addressConfig);

        addToast(t("Adresse {{ addressName }} créée avec succès", { addressName: addressConfig.label }), "success");
        onSave(newAddress.data.id);
        setIsLoading(false);
      }

      onCloseAddressModal();
    } catch (e) {
      setIsLoading(false);
      addToast(t(e.response.data.message));
    }
  };

  const addressTypes = [
    { value: "OFFICE", label: t("Bureau") },
    { value: "HOME", label: t("Domicile") },
    { value: "PO_BOX", label: t("Boîte postale") },
  ];

  return (
    <Modal
      title={address && Object.entries(address).length > 0 ? t("Mettre à jour une adresse") : t("Ajouter une adresse")}
      primaryButton={(
        <Button fullWidth onClick={saveChanges} data-cy="Enregistrer" disabled={buttonDisabled() !== ""} isLoading={isLoading}>
          {t("Enregistrer")}
        </Button>
      )}
      closeModal={onCloseAddressModal}
    >
      {errors && <Alert>{errors}</Alert>}
      <VerticalLayout gap="24" isScrollable>
        <LabeledTextField
          name="label"
          data-cy="shippingLabel"
          value={addressInfo.label || ""}
          onChange={handleChange}
          indication="*"
          placeholder={t("Siège social")}
        >
          {t("Intitulé de l'adresse")}
        </LabeledTextField>
        <VerticalLayout>
          <Label indication="*">{t("Type d'adresse")}</Label>
          <Select
            placeholder={t("Type d'adresse")}
            value={addressInfo.type || ""}
            options={addressTypes}
            onSelect={(option) => setAddressInfo({
              ...addressInfo,
              type: option.value,
            })}
          />
        </VerticalLayout>
        {addressInfo.type === "PO_BOX" && <Alert variant="warning" style={{ width: "100%" }} rounded>{t("L'envoi en boîte postale peut impacter les délais de livraison.")}</Alert>}
        <VerticalLayout gap="16">

          <CompanyAddressesSuggestions companyInfo={addressInfo} setCompanyInfo={setAddressInfo} />

          <LabeledTextField
            data-cy="address2"
            name="address2"
            value={addressInfo.address2 || ""}
            onChange={handleChange}
            placeholder={t("Apt, suite, unité etc…")}
            maxLength="60"
          >
            {t("Complément d'adresse")}
          </LabeledTextField>
          <HorizontalLayout gap={16}>
            <LabeledTextField
              name="zip"
              indication="*"
              data-cy="shippingPostalCode"
              value={addressInfo.zip || ""}
              onChange={handleChange}
              placeholder="75008"
            >
              {t("Code postal")}
            </LabeledTextField>
            <LabeledTextField
              name="city"
              indication="*"
              data-cy="shippingCity"
              value={addressInfo.city || ""}
              onChange={handleChange}
              placeholder="Paris"
            >
              {t("Ville")}
            </LabeledTextField>
          </HorizontalLayout>
          <VerticalLayout>
            <LabeledTextField
              placeholder="Belgique"
              indication="*"
              value={addressInfo.country || ""}
              onChange={handleSelectCountry}
            >
              {t("Pays")}
            </LabeledTextField>
          </VerticalLayout>
          <LabeledTextField
            name="comment"
            value={addressInfo.comment || ""}
            onChange={handleChange}
            placeholder="Code, interphone, bâtiment, étage, etc…"
            type="textarea"
            style={{ width: "100%" }}
          >
            {t("Instructions de livraison")}
          </LabeledTextField>
          <Checkbox
            size="S"
            checked={addressInfo.is_default === 1}
            onClick={() => setAddressInfo({
              ...addressInfo,
              is_default: addressInfo.is_default === 1 ? 0 : 1,
            })}
          >
            <Text size="S">{t("Définir comme adresse par défaut")}</Text>
          </Checkbox>
        </VerticalLayout>

        <VerticalLayout gap="16">
          <Heading size="s">{t("Contact livraison")}</Heading>
          <HorizontalSeparator />
          <LabeledTextField
            name="firstName"
            indication="*"
            data-cy="shippingFirstName"
            value={addressInfo.firstName || ""}
            onChange={handleChange}
            placeholder="Jean"
          >
            {t("Prénom")}
          </LabeledTextField>
          <LabeledTextField
            name="lastName"
            indication="*"
            data-cy="shippingLastName"
            value={addressInfo.lastName || ""}
            onChange={handleChange}
            placeholder="Dupont"
          >
            {t("Nom")}
          </LabeledTextField>
          <VerticalLayout>
            <Label indication="*">{t("Téléphone")}</Label>
            <PhoneInput
              hideLabel
              data-cy="shippingPhoneNumber"
              value={addressInfo.phone}
              country={addressInfo.phone_country}
              onChange={handlePhoneChange}
              setIsPhoneValid={setIsPhoneValid}
            />
          </VerticalLayout>
        </VerticalLayout>
      </VerticalLayout>
    </Modal>
  );
};

export default CompanyAddressesModal;

import React from "react";
import { useTranslation } from "react-i18next";
import { useUserContext } from "src/contexts/UserContext";
import { Layout, Text, TextField } from "tarmac-v3";

const CatalogRangeFilter = (props) => {
  const { filter, filters, onChangeFilters } = props;
  const { user: currentUser } = useUserContext();
  const { t } = useTranslation();
  const minPrice = currentUser.getCompanyCurrency() === "GBP" ? "£ Min" : "Min €";
  const maxPrice = currentUser.getCompanyCurrency() === "GBP" ? "£ Max" : "Max €";

  return (
    <Layout direction="row" spacing={1} data-cy="filterOptions">
      <Layout direction="row" spacing={1} alignItems="center">
        <Text variant="caption">{t("de")}</Text>
        <TextField
          type="number"
          placeholder={minPrice}
          value={filters[`${filter.key}Min`] || ""}
          onChange={(e) => onChangeFilters("priceRangeMin", e.target.value)}
        />
      </Layout>

      <Layout direction="row" spacing={1} alignItems="center">
        <Text variant="caption">{t("à")}</Text>
        <TextField
          type="number"
          placeholder={maxPrice}
          value={filters[`${filter.key}Max`] || ""}
          onChange={(e) => onChangeFilters("priceRangeMax", e.target.value)}
        />
      </Layout>
    </Layout>
  );
};

export default CatalogRangeFilter;

import React, {
  useCallback, useEffect, useMemo, useState,
} from "react";
import { useTranslation } from "react-i18next";
import { faPen, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useUserContext } from "src/contexts/UserContext";
import StripeFormModal from "src/components/cockpit/renewal/StripeFormModal";
import { useLocation } from "react-router-dom";
import { useToastContext } from "src/contexts/ToastContext";
import {
  Button, Card, Icon, IconButton, Layout, Text,
} from "tarmac-v3";

const MandateManager = ({
  setSelectedPaymentMethodId,
}) => {
  const { user: currentUser } = useUserContext();
  const { t } = useTranslation();
  const { search } = useLocation();
  const { addToast } = useToastContext();
  const { pathname } = useLocation();
  const [sepaModalOpen, setSepaModalOpen] = useState(false);
  const ukUser = currentUser.company.country === "United Kingdom";

  const [paymentMethod, setPaymentMethod] = useState();
  const apiKey = ukUser ? process.env.REACT_APP_STRIPE_API_KEY_UK : process.env.REACT_APP_STRIPE_API_KEY_EU;

  const stripePromise = useMemo(() => loadStripe(apiKey), []);

  const fetchPaymentMethod = useCallback(async () => {
    const { data: paymentMethodsData } = await currentUser.api.getPaymentMethods();
    const activePaymentMethod = paymentMethodsData.find((pm) => pm.is_active);

    if (setSelectedPaymentMethodId) {
      setSelectedPaymentMethodId(activePaymentMethod?.id);
    }

    setPaymentMethod(activePaymentMethod);
  }, [currentUser.api, setSelectedPaymentMethodId]);

  useEffect(() => fetchPaymentMethod(), [sepaModalOpen]);

  const createCheckoutSession = async () => {
    const session = await currentUser.api.createCheckoutSession(pathname);

    window.open(session.data.session, "_self");
  };

  useEffect(() => {
    const urlQuery = new URLSearchParams(search);
    const successCode = urlQuery.get("success_code");

    const getInfoFromUrl = () => {
      switch (successCode) {
        case "1":
          addToast(t("Votre autorisation de prélèvements a bien été ajoutée"), "success");
          break;
        case "2":
          addToast(t("Votre autorisation de prélèvements a été abandonnée"));
          break;
        default:
          break;
      }
    };

    try {
      getInfoFromUrl();
    } catch (error) {
      console.error(error);
    }
  }, [search]);

  return (
    <Layout direction="column" fullWidth>
      {!ukUser && (
        paymentMethod ? (
          <Card backgroundColor="secondary" sx={{ p: 1 }}>
            <Layout direction="row" justifyContent="space-between" alignItems="start">
              <Layout direction="column">
                <Text variant="body2" bold color="primary">{paymentMethod?.name}</Text>
                <Text variant="body2" color="secondary">{`**** **** **** **** **** ${paymentMethod?.last4}`}</Text>
              </Layout>
              <IconButton icon={<Icon icon={faPen} />} onClick={() => setSepaModalOpen(true)} />
            </Layout>
          </Card>
        ) : (
          <Button variant="outlined" color="darkGreen" onClick={() => setSepaModalOpen(true)} label={t("Ajouter mandat SEPA")} startIcon={faPlus} />
        )
      )}

      {ukUser && (
        paymentMethod ? (
          <Layout direction="column" fullWidth>
            <Card backgroundColor="secondary" sx={{ p: 1 }}>
              <Layout direction="row" justifyContent="space-between" alignItems="start">
                <Layout direction="column">
                  <Text bold variant="body2" color="primary">{paymentMethod?.name}</Text>
                  <Layout direction="row" gap={4}>
                    <Layout direction="column">
                      {t("Code guichet")}
                      <br />
                      {paymentMethod?.bacs_sort_code}
                    </Layout>
                    <Layout direction="column">
                      {t("Numéro de compte")}
                      <br />
                      {`***${paymentMethod?.last4}`}
                    </Layout>
                  </Layout>
                </Layout>
                <IconButton icon={<Icon icon={faPen} />} onClick={createCheckoutSession} />
              </Layout>
            </Card>
          </Layout>
        ) : (
          <Button variant="outlined" color="primary" onClick={createCheckoutSession} label={t("Mise en place d'un Débit Direct")} startIcon={faPlus} />
        )
      )}

      <Elements stripe={stripePromise}>
        <StripeFormModal open={sepaModalOpen} onClose={() => setSepaModalOpen(false)} />
      </Elements>
    </Layout>

  );
};

export default MandateManager;

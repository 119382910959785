import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import { LabeledTextField } from "tarmac-v2";

const FleetDatePicker = ({
  placeholderText,
  dateFormat,
  onChange,
  value,
  children,
  minDate,
  maxDate,
  filterDate,
  required,
  disabled,
  error,
}) => (
  <DatePicker
    placeholderText={placeholderText}
    dateFormat={dateFormat}
    onChange={onChange}
    selected={value}
    filterDate={filterDate}
    minDate={minDate}
    maxDate={maxDate}
    disabled={disabled}
    customInput={(
      <LabeledTextField icon={faCalendar} indication={required && "*"} error={error}>
        {children}
      </LabeledTextField>
    )}
  />
);

export default FleetDatePicker;

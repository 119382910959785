import React, {
  useEffect, useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  Button, Drawer,
  FileContainer,
  FileDropzone,
  Icon, Layout, Text,
} from "tarmac-v3";

import { useUserContext } from "src/contexts/UserContext";
import {
  downloadFileBlob,
  imgExtensions,
} from "src/tools/FilesHelpers";
import { faDownload, faLock } from "@fortawesome/pro-regular-svg-icons";
import MandateManager from "src/components/cockpit/blocks/billing/MandateManager";
import filesNiceName from "../filesNiceName";

const CompanyFilesModal = (props) => {
  const {
    onClose,
    open,
  } = props;
  const { t } = useTranslation();
  const { user: currentUser, reloadUser } = useUserContext();

  const [files, setFiles] = useState({
    IBAN: null,
  });
  const companyFilesList = ["IBAN"];
  const extraFilesList = ["BALANCE_SHEET"];

  const ukUser = currentUser.company.country === "United Kingdom";
  const mandate = ukUser ? t("Débit Direct") : t("Mandat SEPA");

  const downloadFile = async (file) => {
    const response = await currentUser.api.getFile(file.id);

    downloadFileBlob(response.data, (file.original_name || file.url));
  };

  const deleteFile = async (file, fileType) => {
    await currentUser.api.deleteFile(file.id);
    setFiles((prevFiles) => ({ ...prevFiles, [fileType]: undefined }));
  };

  const submitFile = async (files, type) => {
    const formData = new FormData();

    formData.append("type", type);
    formData.append("status", "TO_EXAMINE");
    formData.append("file_contents", files[0]);
    const { data: newFile } = await currentUser.api.addFile(formData);

    setFiles((prevFiles) => ({ ...prevFiles, [type]: newFile }));
    reloadUser();
  };

  useEffect(() => {
    const iban = currentUser.company.files.find((file) => file.type === "IBAN");
    const balanceSheet = currentUser.company.files.find((file) => file.type === "BALANCE_SHEET");

    if (currentUser.company.country !== "France") {
      setFiles({});

      return;
    }
    const requiredFiles = {
      IBAN: iban,
      BALANCE_SHEET: balanceSheet,
    };

    setFiles(requiredFiles);
  }, [currentUser]);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      title={t("Ajouter les pièces justificatives obligatoires")}
      Actions={(
        <Button
          fullWidth
          variant="contained"
          color="darkGreen"
          onClick={onClose}
          label={t("Valider")}
        />
      )}
    >
      <Layout direction="column" spacing={2} isScrollable>
        <Layout direction="column" spacing={1}>
          <Text>
            {t("Pour créer votre contrat de location, nous devons valider vos informations bancaires. Veuillez noter que vous ne serez pas débité immédiatement, mais uniquement après la validation de votre contrat et la livraison de votre matériel.")}
          </Text>
        </Layout>
        {Object.keys(files).length > 0 && (
          <Layout direction="column" spacing={1}>
            <Text size="m" bold>{t("Relevé d'identité bancaire *")}</Text>
            <Layout direction="column" spacing={1}>
              {companyFilesList.map((fileName) => (
                files[fileName] ? (
                  <FileContainer
                    key={fileName}
                    files={[{ ...files[fileName], name: files[fileName]?.original_name }]}
                    onIconClick={downloadFile}
                    icon={faDownload}
                    onFileDelete={(file) => deleteFile(file, fileName)}
                  />
                ) : (
                  <FileDropzone
                    key={fileName}
                    name={fileName}
                    onFilesAdded={(files) => submitFile(files, fileName)}
                    acceptedExtensions={imgExtensions}
                    helperText={t(filesNiceName[fileName])}
                  />
                )
              ))}
            </Layout>
          </Layout>
        )}
        <>
          <Text size="m" bold>{mandate}</Text>

          <MandateManager onSave={reloadUser} />

          <Layout direction="row" spacing={1}>
            <Icon icon={faLock} />
            <Text size="s" muted>{t("Vos moyens de paiements sont sécurisés par Stripe et ne sont pas stockés par Fleet")}</Text>
          </Layout>
        </>

        {currentUser?.company.country === "France" && (
          <Layout direction="column" spacing={1}>
            <Text size="m" bold>{t("Bilan comptable (optionnel)")}</Text>
            <Layout direction="column" spacing={1}>
              {extraFilesList.map((fileName) => (
                <Layout direction="column" key={fileName} gap={1}>
                  {files[fileName] ? (
                    <FileContainer
                      files={[{ ...files[fileName], name: files[fileName]?.original_name }]}
                      onDownload={downloadFile}
                      onDelete={(file) => deleteFile(file, fileName)}
                    />
                  ) : (
                    <FileDropzone
                      identifier={fileName}
                      onFilesAdded={(e) => submitFile(e, fileName)}
                      acceptedExtensions={imgExtensions}
                      helperText={t(filesNiceName[fileName])}
                    />
                  )}
                  <Text size="s" muted>{t("Ces informations optionnelles permettent de faciliter l'obtention d'un accord de financement. Elles pourront vous être demandées ultérieurement.")}</Text>
                </Layout>
              ))}
            </Layout>
          </Layout>
        )}
      </Layout>
    </Drawer>
  );
};

export default CompanyFilesModal;

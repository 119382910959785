import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { useToastContext } from "src/contexts/ToastContext";

import AuthService from "src/components/auth/auth-service";
import {
  Button, Card, Heading, Layout, Text, TextField,
} from "tarmac-v3";
import useDeviceType from "src/hooks/useDeviceType";
import { useFormValidationContext } from "src/contexts/FormValidationContext";

const ResetPassword = (props) => {
  const { backToLogin } = props;
  const { addToast } = useToastContext();
  const { t } = useTranslation();
  const service = new AuthService();
  const deviceType = useDeviceType();
  const isMobile = deviceType !== "desktop";

  const [email, setEmail] = useState("");
  const [resetSent, setResetSent] = useState(false);
  const { setErrors, errors } = useFormValidationContext();

  const checkFields = () => {
    const validations = [
      {
        name: "missing_email",
        message: t("Veuillez entrer votre adresse e-mail professionnelle"),
        validator: () => Boolean(email),
      },
    ];

    const validationErrors = {};

    for (const validation of validations) {
      if (!validation.validator()) {
        validationErrors[validation.name] = validation.message;
      }
    }

    setErrors(validationErrors);

    return Object.keys(validationErrors).length === 0;
  };

  const handleSubmit = async () => {
    setErrors({});
    setResetSent(true);
    // Update state before async call
    if (checkFields()) {
      try {
        await service.requestToken(email);
        addToast(t("Un e-mail a été envoyé à {{ userEmail }} contenant des instructions pour réinitialiser votre mot de passe", { userEmail: email }), "success");
      } catch (error) {
        console.error(error);
        addToast(t(error.response?.data?.message || "Une erreur est survenue lors de la connexion"));
      }
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit(event);
    }
  };

  return (
    <Card sx={{ width: isMobile ? "100%" : 450 }} spacing={isMobile ? 2 : 3}>
      <Layout direction="column" spacing={4}>
        <Layout display="inline" alignSelf="center">
          <Heading variant="h3">
            {t("Mot de passe oublié")}
          </Heading>
        </Layout>
        {!resetSent && (
          <Text textAlign="center">
            {t("Vous avez oublié votre mot de passe ? Saisissez l'adresse e-mail pour réinitialiser votre mot de passe.")}
          </Text>
        )}

        <Layout direction="column" gap={2} alignItems="center" fullWidth>
          <Layout direction="column" gap={2} alignItems="center" fullWidth>
            <Layout direction="column" gap={1} alignItems="left" fullWidth>
              <TextField
                label={t("Adresse e-mail professionnelle")}
                fullWidth
                name="email"
                type="email"
                placeholder={t("Email")}
                indication="*"
                onChange={(e) => setEmail(e.target.value)}
                onKeyPress={handleKeyPress}
              />
              {errors?.missing_email && <Text variant="body2" color="error" align="left">{t(errors.missing_email)}</Text>}
            </Layout>
          </Layout>

        </Layout>
        <Layout>
          <Layout direction="column" spacing={2} alignItems="center" fullWidth>
            <Button
              label={t("Modifier mon mot de passe")}
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSubmit}
            />
            <Button
              label={(
                <Text bold variant="body2">
                  {t("Revenir à la page de connexion")}
                </Text>
              )}
              variant="text"
              color="secondary"
              data-cy="primaryLink"
              onClick={backToLogin}
            />
          </Layout>
        </Layout>
      </Layout>
    </Card>
  );
};

export default ResetPassword;

import React from "react";
import { useTranslation } from "react-i18next";
import { Icon, Layout } from "tarmac-v3";
import { faCircleSmall } from "@fortawesome/pro-solid-svg-icons";

import styles from "./OldDeviceStatus.module.scss";

const OldDeviceStatus = ({ device }) => {
  let label;
  let statusClassName;
  const { t } = useTranslation();

  if (!device) return null;

  if (device.status === "WAITING") {
    label = "En traitement";
    statusClassName = styles.statusInProgress;
  } else if (device.status === "PURCHASED") {
    label = "En préparation";
    statusClassName = styles.statusInProgress;
  } else if (device.status === "SHIPPED") {
    label = "En livraison";
    statusClassName = styles.statusInProgress;
  } else if (
    ["DELIVERED", "BDL_SENT", "RECEIVED", "RUNNING"].includes(device.status)
    && !["SAV", "OUT_OF_SERVICE", "SPARE", "ARCHIVED"].includes(
      device.usage_status,
    )
    && (device.end_of_life_status !== "DONE" || device.renewal_type === "BUYBACK")
  ) {
    label = "En service";
    statusClassName = styles.statusRunning;
  } else if (device.usage_status === "SAV") {
    label = "En maintenance";
    statusClassName = styles.statusUnderMaintenance;
  } else if (
    device.usage_status === "OUT_OF_SERVICE"
    || (device.end_of_life_status === "DONE" && device.renewal_type !== "BUYBACK")
  ) {
    label = "Hors service";
    statusClassName = styles.statusOutOfService;
  } else if (device.usage_status === "SPARE") {
    label = "Disponible";
    statusClassName = styles.statusRunning;
  } else if (device.usage_status === "ARCHIVED") {
    label = "Archivé";
    statusClassName = styles.statusUnassigned;
  }

  return (
    <Layout
      direction="row"
      className={`${styles.statusWrapper} ${statusClassName}`}
    >
      <Icon color={statusClassName} icon={faCircleSmall} size="s" />
      {" "}
      {t(label)}
    </Layout>
  );
};

export default OldDeviceStatus;

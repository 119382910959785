import React from "react";
import { useTranslation } from "react-i18next";
import { Card, Layout, Text } from "tarmac-v3";
import CImage from "../cockpit/blocks/CImage";

const GoogleConnect = (props) => {
  const { onConnect } = props;
  const { t } = useTranslation();

  return (
    <Card
      color="secondary"
      onClick={onConnect}
      sx={{
        borderColor: "black",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <Layout direction="row" justifyContent="center" alignItems="center" spacing={2}>
        <Layout alignItems="center">
          <CImage label="google.webp" width={16} height={16} />
        </Layout>
        <Text
          sx={{
            fontWeight: "500",
          }}
        >
          {t("Continuer avec Google")}
        </Text>
      </Layout>
    </Card>
  );
};

export default GoogleConnect;

import React, { useState } from "react";
import { Label, Search, VerticalLayout } from "tarmac-v2";
import OutsideClickHandler from "src/components/utils/OutsideClickHandler";
import PlaceService from "src/tools/PlaceService";
import { useTranslation } from "react-i18next";
import useDebouncedEffect from "src/components/utils/useDebouncedEffect";
import styles from "./CompanyAddressesModal.module.scss";

const CompanyAddressesSuggestions = (props) => {
  const { companyInfo, setCompanyInfo } = props;
  const { t } = useTranslation();
  const [suggestions, setSuggestions] = useState([]);
  const [isAddressSearchResultsVisible, setIsAddressSearchResultsVisible] = useState(false);

  const placeService = new PlaceService();

  // FUNCTIONS

  const clearSuggestions = () => {
    setSuggestions([]);
  };

  const handleSearch = async (e) => {
    if (!e) {
      return;
    }

    setCompanyInfo({
      ...companyInfo,
      address1: e.target.value,
    });

    const suggestionsFromApi = await placeService.getPlaceIdFromText(e.target.value);

    if (!isAddressSearchResultsVisible) {
      setIsAddressSearchResultsVisible(true);
    }

    setSuggestions(suggestionsFromApi);
  };

  useDebouncedEffect(handleSearch, 500, [companyInfo.address1]);
  // Handle address select in results box
  const handleSelect = async (suggestion) => {
    const placeId = suggestion.place_id;
    const addressFromApi = await placeService.getAddressForPlaceId(placeId);

    setCompanyInfo({
      ...companyInfo,
      ...addressFromApi,
      address1: suggestion.structured_formatting.main_text || "",
    });

    clearSuggestions();
  };

  return (
    <OutsideClickHandler action={() => setIsAddressSearchResultsVisible(false)}>
      <VerticalLayout className={styles.adressField}>
        <Label indication="*">{t("Adresse")}</Label>
        <Search
          name="address1"
          placeholder={t("Rechercher une adresse")}
          value={companyInfo.address1}
          autoComplete="off"
          onChange={handleSearch}
          data-cy="shippingAddress"
          style={{ width: "100%" }}
          maxLength="40"
        />
        {(suggestions.length > 0 && isAddressSearchResultsVisible)
          && (
            <VerticalLayout className={styles.addressSearchResultsContainer}>
              {suggestions.map((suggestion, index) => (
                <div className={styles.addressSearchResults} key={index} onClick={() => handleSelect(suggestion)} aria-hidden>
                  <span>{suggestion.description}</span>
                </div>
              ))}
            </VerticalLayout>
          )}
      </VerticalLayout>
    </OutsideClickHandler>

  );
};

export default CompanyAddressesSuggestions;

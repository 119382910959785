import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import * as Sentry from "@sentry/react";

import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import "moment/locale/fr";
import { LicenseInfo } from "@mui/x-license-pro";

import "@fontsource/poppins/400.css";
import "@fontsource/poppins/500.css";
import "@fontsource/poppins/600.css";
import "@fontsource/poppins/700.css";

import { IntercomProvider } from "react-use-intercom";
import { ThemeProvider, theme } from "tarmac-v3";

import ScrollToTop from "src/tools/ScrollToTop";
import MainLoader from "src/components/MainLoader";
import { UserProvider } from "src/contexts/UserContext";
import { ToastProvider } from "src/contexts/ToastContext";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

async function startApp() {
  if (process.env.REACT_APP_SENTRY_DSN) {
    const sentryEnv = process.env.REACT_APP_SENTRY_ENV || process.env.NODE_ENV || "development";

    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      environment: sentryEnv,
      integrations: [
        new Sentry.BrowserTracing({ tracePropagationTargets: ["localhost:3000"] }),
        new Sentry.Replay(),
      ],
      // Performance Monitoring
      // Capture 10% of the transactions
      tracesSampleRate: 0.1,
      // Session Replay
      // This sets the sample rate at 10%.
      // You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysSessionSampleRate: 0.1,
      // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      replaysOnErrorSampleRate: 1.0,
    });
  }

  // Set MUI License Key
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_ID);

  ReactDOM.render(
    <Router>
      <Suspense fallback={<MainLoader />}>
        <IntercomProvider
          appId={process.env.REACT_APP_INTERCOM_APP_ID}
          autoBoot={false}
          shouldInitialize={false}
        >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <ThemeProvider theme={theme}>
              <ScrollToTop>
                <UserProvider>
                  <ToastProvider>
                    <App />
                  </ToastProvider>
                </UserProvider>
              </ScrollToTop>
            </ThemeProvider>
          </LocalizationProvider>
        </IntercomProvider>
      </Suspense>
    </Router>,
    document.getElementById("root"),
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: https://bit.ly/CRA-PWA
  serviceWorker.unregister();
}

startApp();

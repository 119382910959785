import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Checkbox, Layout, Text } from "tarmac-v3";

const CatalogSelectFilter = (props) => {
  const {
    filter,
    category,
    filters,
    onChangeFilters,
    resetFilter,
  } = props;
  const { t } = useTranslation();

  const isOptionAnySelected = useCallback((filterKey) => {
    if (filters[filterKey] === null) {
      return true;
    }

    if (Array.isArray(filters[filterKey]) && filters[filterKey].length === 0) {
      return true;
    }

    return false;
  }, [filters]);

  const isOptionSelected = useCallback((filterKey, optionValue) => {
    if (filters[filterKey] === optionValue) {
      return true;
    }

    if (Array.isArray(filters[filterKey]) && filters[filterKey].includes(optionValue)) {
      return true;
    }

    return false;
  }, [filters]);

  return (
    <Layout direction="column" data-cy="filterOptions">
      <Checkbox
        label={<Text variant="caption">{t("Peu importe")}</Text>}
        color="secondary"
        checked={isOptionAnySelected(filter.key)}
        disabled={isOptionAnySelected(filter.key)}
        onChange={() => resetFilter(filter.key)}
      />

      {filter.options(category.name).map((option) => (
        <Checkbox
          label={<Text variant="caption">{option.translate ? t(option.label) : option.label}</Text>}
          color="secondary"
          checked={isOptionSelected(filter.key, option.value)}
          onChange={() => onChangeFilters(filter.key, option.value)}
        />
      ))}
    </Layout>
  );
};

export default CatalogSelectFilter;

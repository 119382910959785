import React from "react";
import {
  Avatar,
  Badge,
  Icon,
  Tooltip,
  theme,
} from "tarmac-v3";
import { whichIconTypeToShow } from "src/tools/IconHelpers";
import { getDeviceStatusLabelAndColor } from "src/tools/DeviceHelpers";
import { useTranslation } from "react-i18next";
import styles from "../ActiveLaunchpad.module.scss";

const DeviceBadge = (props) => {
  const { device, displayStatusBadge } = props;
  const { color, label } = getDeviceStatusLabelAndColor(device);
  const { t } = useTranslation();

  return (
    displayStatusBadge ? (
      <Tooltip title={t(label)} placement="top">
        <Badge
          color={color}
          variant="dot"
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          className={styles.badge}
        >
          <Avatar
            size={24}
            color={(device && device?.source === "FLEET") ? "white" : theme.palette.green[100]}
            src={(device && device.product?.img_url)}
            icon={(device && !device.product?.img_url) && <Icon icon={whichIconTypeToShow(device)} color="secondary" size="S" />}
            className={styles.avatar}
          />
        </Badge>
      </Tooltip>
    ) : (
      <Avatar
        size={24}
        color={(device && device?.source === "FLEET") ? "white" : theme.palette.green[100]}
        src={(device && device.product?.img_url)}
        icon={(device && !device.product?.img_url) && <Icon icon={whichIconTypeToShow(device)} color="secondary" size="S" />}
        className={styles.avatar}
      />
    )
  );
};

export default DeviceBadge;

import { getTicketCategory } from "./TicketHelpers";

const cleanSearch = (search) => {
  const escapedSearch = search.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  const searchExpr = new RegExp(escapedSearch.trim(), "i");

  return searchExpr;
};

const tagMatches = (tag, search) => {
  const searchExpr = cleanSearch(search);

  return (tag.label || "").match(searchExpr);
};

const employeeMatches = (user, search) => {
  const searchExpr = cleanSearch(search);
  const tagMatch = user?.tags?.filter((tag) => tagMatches(tag, search))?.length;

  return (
    (user.firstName || "").match(searchExpr)
    || (user.lastName || "").match(searchExpr)
    || (user.email || "").match(searchExpr) || tagMatch
  );
};

const orderMatches = (order, search) => {
  const searchExpr = cleanSearch(search);

  return (
    (order.order_number || "").match(searchExpr)
    || (order.contract_number || "").match(searchExpr)
    || (order.request_number || "").match(searchExpr)
    || (order.leaser || "").match(searchExpr)
  );
};

const deviceMatches = (device, search) => {
  const searchExpr = cleanSearch(search);

  let tagMatch;

  if (device.tags) {
    tagMatch = device.tags.filter((tag) => tagMatches(tag, search)).length;
  }

  return (
    (device.serial_number || "").match(searchExpr)
    || (device.tracking_number || "").match(searchExpr)
    || (device.name || "").match(searchExpr)
    || (device.internal_id || "").match(searchExpr)
    || (device.user && employeeMatches(device.user, search)) || tagMatch
  );
};

const ticketMatches = (ticket, search) => {
  const searchExpr = cleanSearch(search);
  const users = ticket.ticket_devices.map((item) => item.device.user).filter((n) => n);
  const userMatch = users.filter((user) => employeeMatches(user, search)).length;
  const ticketCategory = getTicketCategory(ticket);

  return (ticket.content || "").match(searchExpr) || (ticketCategory.label || "").match(searchExpr) || userMatch;
};

const productGroupMatches = (productGroup, search) => {
  const searchExpr = cleanSearch(search);

  return (
    (productGroup.name || "").match(searchExpr)
    || (productGroup.brand || "").match(searchExpr)
  );
};

export {
  cleanSearch,
  employeeMatches,
  orderMatches,
  deviceMatches,
  ticketMatches,
  productGroupMatches,
};

import React from "react";
import { Layout } from "tarmac-v3";
import CImage from "./cockpit/blocks/CImage";

const MainLoader = () => (
  <Layout alignItems="center" justifyContent="center" sx={{ height: "100vh", width: "100%" }}>
    <CImage label="hourglass_loader_bc89ze" width={128} />
  </Layout>
);

export default MainLoader;

import React, { useEffect } from "react";
import { Link as ReactLink } from "react-router-dom";

import {
  Layout, Link, Select, Text,
} from "tarmac-v3";
import useDeviceType from "src/hooks/useDeviceType";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import i18n from "src/i18n";
import useLocalStorage from "src/hooks/useLocalStorage";
import CImage from "../cockpit/blocks/CImage";

const LoginWrapper = (props) => {
  const { children } = props;
  const { t } = useTranslation();
  const deviceType = useDeviceType();
  const isMobile = deviceType !== "desktop";
  const { search } = useLocation();

  const urlQuery = new URLSearchParams(search);
  const sourceLanguage = urlQuery.get("language");

  const shortNavLanguage = navigator.language.substring(0, 2);
  const supportedLangs = {
    fr: "fr",
    en: "en",
    de: "de",
    es: "es",
  };
  const formattedNavLanguage = supportedLangs[shortNavLanguage];

  const [language, setLanguage] = useLocalStorage("user_language", sourceLanguage || formattedNavLanguage || "en");

  const gradientColorBg = "https://res.cloudinary.com/fleet-co/image/upload/v1689065971/fond_website_lqfzaw.jpg";

  const languageOptions = [
    {
      label: "🇫🇷 Français",
      value: "fr",
    },
    {
      label: "🇬🇧 English",
      value: "en",
    },
    {
      label: "🇪🇸 Español",
      value: "es",
    },
    {
      label: "🇩🇪 Deutsch",
      value: "de",
    },
  ];

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  return (
    <Layout
      direction="column"
      spacing={isMobile ? 2 : 0}
      alignItems="center"
      justifyContent="center"
      fullWidth
      sx={{
        paddingX: isMobile ? 2 : 4,
        paddingY: 2,
        backgroundImage: `url(${gradientColorBg})`,
        height: "100vh",
      }}
    >
      <Layout direction="column" alignSelf={isMobile ? "center" : "flex-start"}>
        <CImage label="fleet_newLogo_wdsjyf" width={92} />
      </Layout>
      <Layout
        direction="column"
        spacing={4}
        fullHeight
        justifyContent={isMobile ? "space-between" : "center"}
        fullWidth={isMobile}
      >
        {children}
        <Layout direction="column" spacing={2}>
          <Layout
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={2}
            mobileDirection="column"
          >
            <Text variant="body1">{t("Pas encore de compte ?")}</Text>
            <Link
              label={<Text color="inherit" variant="body2" bold>{t("S'inscrire gratuitement")}</Text>}
              color="darkGreen"
              to="/register"
              iconRight
              sx={{
                fontWeight: 600,
              }}
              component={ReactLink}
            />
          </Layout>
          <Layout>
            <Select
              options={languageOptions}
              value={language}
              onChange={(newValue) => setLanguage(newValue)}
            />
          </Layout>
        </Layout>

      </Layout>
    </Layout>
  );
};

export default LoginWrapper;

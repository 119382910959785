import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Alert, Button, Label, Modal, VerticalLayout,
} from "tarmac-v2";

import OldTagsAttribution from "src/components/cockpit/blocks/tags/OldTagsAttribution";
import OldTagModification from "src/components/cockpit/blocks/tags/OldTagModification";
import { useUserContext } from "src/contexts/UserContext";

const TaggingModal = (props) => {
  // PROPS & CONTEXT
  const { onSave, onClose, device } = props;
  const { user: currentUser } = useUserContext();
  const { t } = useTranslation();

  // GENERAL STATES
  const [errors, setErrors] = useState("");

  // INITIALISATION STATES
  const [deviceInfo, setDeviceInfo] = useState({});
  const [tagInfo, setTagInfo] = useState({});
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (device) {
      setDeviceInfo({ ...device });
    }
  }, [device]);

  // FUNCTIONS
  const onCloseTaggingModal = () => {
    setErrors("");
    onSave(deviceInfo.id);
    onClose();
  };

  const onSaveConfirmTagging = async () => {
    const tagIds = deviceInfo.tags.map((t) => t.id);
    const deletedTagsIds = device.tags.filter((t) => !tagIds.includes(t.id)).map((t) => t.id);
    const devicePatch = { new_tags: tagIds, deleted_tags: deletedTagsIds };

    try {
      if (deviceInfo) await currentUser.api.modifyDevice(deviceInfo.id, devicePatch);
      onSave();
      onClose();
    } catch (err) {
      setErrors(err.response.data.message);
    }
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onSaveConfirmModifying = async () => {
    try {
      if (Object.keys(tagInfo).length) await currentUser.api.updateTag(tagInfo.id, tagInfo);
      onSave();
      closeMenu();
    } catch (err) {
      setErrors(err.response.data.message);
    }
  };

  const primaryButton = isMenuOpen ? (
    <Button fullWidth onClick={onSaveConfirmModifying}>
      {t("Modifier le tag")}
    </Button>
  ) : (
    <Button fullWidth onClick={onSaveConfirmTagging}>
      {t("Enregistrer ")}
    </Button>
  );

  const secondaryButton = isMenuOpen ? (
    <Button fullWidth onClick={closeMenu}>
      {t("Annuler")}
    </Button>
  ) : (
    <Button fullWidth onClick={onClose}>
      {t("Fermer")}
    </Button>
  );

  return (
    <Modal
      closeModal={onCloseTaggingModal}
      title={t("Assigner un tag")}
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
    >
      <VerticalLayout gap={8} isScrollable>
        {errors && <Alert onClick={() => setErrors("")}>{errors}</Alert>}

        {!isMenuOpen ? (
          <>
            <Label>{t("Tags")}</Label>
            <OldTagsAttribution
              device={deviceInfo}
              tagInfo={tagInfo}
              onChange={(tags) => setDeviceInfo({ ...deviceInfo, tags })}
              setTagInfo={setTagInfo}
              toggleMenu={toggleMenu}
              isEditable
            />
          </>
        ) : (
          <OldTagModification
            closeMenu={closeMenu}
            getTagColor={() => tagInfo.color}
            tag={tagInfo}
            setTagInfo={setTagInfo}
          />
        )}
      </VerticalLayout>
    </Modal>
  );
};

export default TaggingModal;

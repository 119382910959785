import React, { useState } from "react";
import {
  Avatar,
  Checkbox,
  Heading,
  HorizontalLayout,
  Icon,
  ListCard,
  StackedLayout,
  Tag,
  Text,
  VerticalLayout,
  VerticalSeparator,
} from "tarmac-v2";
import { faLaptopMobile } from "@fortawesome/pro-regular-svg-icons";

import tagColorsAndNames from "src/data/tagColorsAndNames.json";
import ScrollToDiv from "../ScrollToDiv";

import styles from "./ListCard.module.scss";

const EmployeeSelectableListCard = (props) => {
  const {
    employee,
    isActive,
    onCardClick,
    toggleSelected,
    isSelected,
    canHover = true,
    canSelect = true,
    selectMode,
    grow,
    noCheckbox,
  } = props;
  const [isHovering, setIsHovering] = useState(false);

  const onHover = () => {
    if (canSelect) setIsHovering(true);
  };
  const onQuitHover = () => {
    if (canSelect) setIsHovering(false);
  };
  const onCheckboxClicked = (event) => {
    event.stopPropagation();
    if (canSelect) toggleSelected(employee);
  };
  const showCheckbox = !noCheckbox && (isHovering || isSelected || selectMode);

  const initials = (employee?.firstName ? employee.firstName[0] : "") + (employee?.lastName ? employee.lastName[0] : "");

  let avatarColor;

  if (employee && !employee.photo) {
    const idx = employee.id % tagColorsAndNames.length;

    avatarColor = tagColorsAndNames[idx].color;
  }

  return (
    <ScrollToDiv shouldScrollTo={isActive}>
      <ListCard
        canHover={canHover}
        canSelect={canSelect}
        isActive={isActive}
        onClick={() => onCardClick(employee)}
        {...(grow && { style: { flexGrow: 1 } })}
      >
        <HorizontalLayout gap="8" alignItems="center" fullWidth>
          <StackedLayout onMouseEnter={onHover} onMouseLeave={onQuitHover}>
            <Avatar
              letters={initials.toUpperCase()}
              url={employee.photo_url}
              isTransparent={showCheckbox}
              color={avatarColor}
            />
            {showCheckbox && (
            <Checkbox style={{ zIndex: "2", fontSize: "20px" }} onClick={onCheckboxClicked} checked={isSelected} />
            )}
          </StackedLayout>
          <VerticalLayout fullWidth gap={employee?.tags?.length > 0 || employee?.used_devices?.length > 0 ? "8" : "0"}>
            <Heading className={styles.overflowHeading} size="XS">
              {`${employee.firstName} ${employee.lastName}`}
            </Heading>

            <HorizontalLayout gap="8">
              {employee?.tags?.length > 0 && (
              <HorizontalLayout gap="8">
                <Tag filled small color={employee.tags[0].color} label={employee.tags[0].label} />
                {employee.tags.length > 1 && (
                <Tag filled small color={employee.tags[0].color} label={`+ ${employee.tags.length - 1}`} />
                )}
              </HorizontalLayout>
              )}

              {employee?.used_devices?.length > 0 && (
              <>
                <VerticalSeparator />
                <HorizontalLayout gap="8">
                  <Icon size="S" icon={faLaptopMobile} />
                  <Text size="M">{employee.used_devices.length}</Text>
                </HorizontalLayout>
              </>
              )}
            </HorizontalLayout>
          </VerticalLayout>
        </HorizontalLayout>
      </ListCard>
    </ScrollToDiv>
  );
};

export default EmployeeSelectableListCard;

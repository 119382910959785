import {
  faKeyboard,
  faLaptopCode, faLock, faRocketLaunch, faShieldCheck,
} from "@fortawesome/pro-regular-svg-icons";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import DeviceBadge from "src/components/cockpit/launchpad/sections/activeState/blocks/DeviceBadge";
import { useCurrentOrderContext } from "src/contexts/CurrentOrderContext";
import { useUserContext } from "src/contexts/UserContext";
import MoneyFormat from "src/tools/MoneyFormat";
import {
  Avatar,
  Button,
  Card,
  DataGrid, Dialog, Heading, Icon, Layout, Text, theme,
} from "tarmac-v3";

const firstPartDialogContent = [
  {
    icon: faLaptopCode,
    title: "Contrôle des appareils",
    content: "Réinitialisation, vérrouillage, mise à jour de vos appareils à distance et en un clic.",
  },
  {
    icon: faLock,
    title: "Sécurisation des appareils",
    content: "Accédez aux différents réglages de sécurité de vos appareils pour protéger les données personnelles de vos employés.",
  },
  {
    icon: faRocketLaunch,
    title: "Automatisation des onboardings",
    content: "Configurez les appareils de vos employés dès leur arrivée.",
  },
];

const SecureMdmCartDialog = (props) => {
  const { open, setOpen, orderInfo } = props;
  const { user: currentUser, reloadUser } = useUserContext();
  const { reloadOrder } = useCurrentOrderContext();
  const { t } = useTranslation();
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [nextPart, setNextPart] = useState(false);

  const isEnrollRequest = open === "ENROLL";

  const filteredDevices = orderInfo.devices
    ?.filter((device) => device.category === "COMPUTER")
    .filter((device) => (isEnrollRequest ? !device.order_mdm_activation_consent : device.order_mdm_activation_consent))
    .sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

  const unenrollOrEnrollDevices = async () => {
    selectedDevices.map(async (deviceId) => {
      if (isEnrollRequest) {
        await currentUser.api.modifyDevice(deviceId, { order_mdm_activation_consent: true });
      } else {
        await currentUser.api.modifyDevice(deviceId, { order_mdm_activation_consent: false });
      }
    });

    await reloadOrder();
    await reloadUser();
    setOpen(false);
    setNextPart(false);
  };

  const desktopColumns = useMemo(() => [
    {
      field: "devices",
      minWidth: 260,
      headerName: t("Équipement"),
      valueGetter: ({ row: device }) => device.name,
      renderCell: ({ row: device }) => (
        <Layout direction="row" fullWidth alignItems="center" spacing={1}>
          <DeviceBadge device={device} />
          <Layout direction="column" spacing={0.5}>
            <Text variant="body2" bold>{device.name}</Text>
            <Layout direction="row" spacing={0.5}>
              <Text variant="body2">{device.keyboard_layout}</Text>
              <Icon icon={faKeyboard} size="S" />
            </Layout>
          </Layout>
        </Layout>
      ),
    },
    {
      field: "price",
      headerName: t("Prix/mois"),
      valueGetter: ({ row: device }) => device.price,
      renderCell: ({ row: device }) => (
        <Layout direction="row" fullWidth justifyContent="flex-end">
          <Text variant="body1">
            <Text size="S">
              <MoneyFormat value={device.base_price} />
              {t("/mois")}
            </Text>
          </Text>
        </Layout>
      ),
      headerAlign: "right",
    },
  ], [t]);

  const closeDialog = () => {
    setOpen(false);
    setNextPart(false);
  };

  const firstActions = (
    <>
      <Button
        variant="text"
        color="secondary"
        label={t(isEnrollRequest ? "Retour au panier" : "Retirer la sécurisation")}
        onClick={() => (isEnrollRequest ? setOpen(false) : setNextPart(true))}
        size="small"
      />
      <Button
        variant="contained"
        color="darkGreen"
        size="small"
        label={t(isEnrollRequest ? "Sécuriser vos appareils" : "Retour au panier")}
        onClick={() => (isEnrollRequest ? setNextPart(true) : setOpen(false))}
      />
    </>
  );

  const secondActions = (
    <>
      <Button
        variant="text"
        color="secondary"
        label={t("Annuler")}
        onClick={closeDialog}
        size="small"
      />
      <Button
        variant="contained"
        color={isEnrollRequest ? "success" : "error"}
        size="small"
        label={t(isEnrollRequest ? "Sécuriser vos appareils" : "Retirer la sécurisation")}
        onClick={unenrollOrEnrollDevices}
      />
    </>
  );

  const companyMdmUnitAmount = (currentUser.company.mdm_unit_amount / 100).toFixed(2);

  return (
    <Dialog
      title={(
        <Layout direction="row" spacing={1}>
          <Icon icon={faShieldCheck} color="secondary" size="M" />
          <Heading variant="h5" bold>
            {t("Sécurisation de votre appareil avec le MDM")}
          </Heading>
        </Layout>
      )}
      onClose={closeDialog}
      open={open}
      Actions={nextPart ? secondActions : firstActions}
    >
      {nextPart ? (
        <Layout direction="column" spacing={2}>

          <Text variant="body2">
            {open === "UNENROLL"
              ? t("Veuillez sélectionner les équipements sur lesquels vous souhaitez retirer la sécurisation MDM.")
              : t("Veuillez sélectionner les équipements sur lesquels vous souhaitez ajouter la sécurisation MDM.")}
          </Text>

          <Layout sx={{ height: filteredDevices.length > 0 && "258px" }}>
            <DataGrid
              columns={desktopColumns}
              rows={filteredDevices}
              rowHeight={56}
              checkboxSelection
              disableColumnReorder
              disableColumnResize
              hideFooter
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setSelectedDevices(newRowSelectionModel);
              }}
            />
          </Layout>

        </Layout>
      ) : (
        <Layout direction="column" spacing={2}>
          <Text variant="body2" color="secondary">
            {t(
              "Service gratuit pendant 3 mois, puis {{ unit_amount }} par appareil et par mois, sans engagement.",
              { unit_amount: currentUser.getCompanyCurrency() === "GBP" ? `£ ${companyMdmUnitAmount}` : `${companyMdmUnitAmount} €` },
            )}
          </Text>
          <Layout direction="column" spacing={1}>
            {firstPartDialogContent.map((info) => (
              <Card key={info.title} backgroundColor="secondary" sx={{ border: "none" }}>
                <Layout direction="row" spacing={1}>
                  <Avatar
                    size={56}
                    icon={<Icon icon={info.icon} color="darkGreen" size="M" />}
                    color={theme.palette.background.primary}
                  />
                  <Layout direction="column" spacing={0.5} fullHeight alignItems="space-around">
                    <Text variant="body2" bold>{t(info.title)}</Text>
                    <Text variant="caption" color="secondary">{t(info.content)}</Text>
                  </Layout>
                </Layout>
              </Card>
            ))}
          </Layout>
        </Layout>
      )}

    </Dialog>
  );
};

export default SecureMdmCartDialog;

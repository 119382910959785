import { styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TagModification from "src/components/cockpit/blocks/tags/TagModification";
import TagsAttribution from "src/components/cockpit/blocks/tags/TagsAttribution";
import { useUserContext } from "src/contexts/UserContext";
import {
  Alert, Button, Drawer, Layout, Text,
} from "tarmac-v3";
import { useEmployeeListContext } from "src/contexts/EmployeeListContext";

const StyledDrawer = styled(Drawer)({
  "& .MuiStack-root": {
    height: "auto",
  },
});

const TaggingUserModal = (props) => {
  const {
    isOpen, setIsOpen, employee, handleClose, onSave,
  } = props;
  const { user: currentUser } = useUserContext();
  const { t } = useTranslation();
  const [errors, setErrors] = useState("");
  const [employeeInfo, setEmployeeInfo] = useState({});
  const [tagInfo, setTagInfo] = useState({});
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { reloadEmployeesList } = useEmployeeListContext();

  useEffect(() => {
    if (employee) {
      setEmployeeInfo({ ...employee });
    }
  }, [employee]);

  const onSaveConfirmTagging = async () => {
    const tagIds = employeeInfo.tags.map((t) => t.id);

    const deletedTagsIds = employee.tags
      .filter((t) => !tagIds.includes(t.id))
      .map((t) => t.id);

    const employeePatch = { new_tags: tagIds, deleted_tags: deletedTagsIds };

    try {
      if (employeeInfo) {
        const updatedEmployee = await currentUser.api.modifyUser(
          employeeInfo.id,
          employeePatch,
        );

        setEmployeeInfo(updatedEmployee);
      }
      onSave(employee.id);
      await reloadEmployeesList();
      setIsOpen(false);
    } catch (err) {
      setErrors(err.response.data.message);
    }
  };

  const closeDrawer = () => {
    setIsMenuOpen(false);
    handleClose();
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const onSaveConfirmModifying = async () => {
    try {
      if (Object.keys(tagInfo).length) await currentUser.api.updateTag(tagInfo.id, tagInfo);
      closeMenu();
    } catch (err) {
      setErrors(err.response.data.message);
    }
  };

  const actions = isMenuOpen ? (
    <>
      <Button
        variant="outlined"
        onClick={closeMenu}
        color="secondary"
        label={t("Annuler")}
        fullWidth
      />
      <Button
        variant="contained"
        onClick={onSaveConfirmModifying}
        color="darkGreen"
        label={t("Modifier le tag")}
        fullWidth
      />
    </>
  ) : (
    <>
      <Button
        variant="outlined"
        onClick={closeDrawer}
        color="secondary"
        label={t("Annuler")}
        fullWidth
      />
      <Button
        variant="contained"
        onClick={onSaveConfirmTagging}
        color="darkGreen"
        label={t("Enregistrer")}
        fullWidth
      />
    </>
  );

  return (
    <StyledDrawer
      title={t("Assigner des équipes")}
      open={isOpen}
      onClose={closeDrawer}
      Actions={actions}
      zIndex={10}
    >
      <Layout direction="column" spacing={1} justifyContent="space-between">
        {errors && <Alert onClick={() => setErrors("")}>{errors}</Alert>}

        {!isMenuOpen ? (
          <>
            <Text variant="body2" bold>
              {t("Équipes")}
            </Text>
            <TagsAttribution
              user={employeeInfo}
              tagInfo={tagInfo}
              onChange={(tags) => setEmployeeInfo({ ...employeeInfo, tags })}
              setTagInfo={setTagInfo}
              toggleMenu={toggleMenu}
              isEditable
            />
          </>
        ) : (
          <TagModification
            closeMenu={closeMenu}
            getTagColor={() => tagInfo.color}
            tag={tagInfo}
            setTagInfo={setTagInfo}
          />
        )}
      </Layout>
    </StyledDrawer>
  );
};

export default TaggingUserModal;

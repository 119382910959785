import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  Button, Layout, Tag, TextField,
} from "tarmac-v3";

import { useUserContext } from "src/contexts/UserContext";
import { useToastContext } from "src/contexts/ToastContext";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import TagSelectAttribution from "./TagSelectAttribution";

const TagsAttribution = (props) => {
  const {
    user,
    device,
    onChange,
    isChanging,
    toggleMenu,
    setTagInfo,
    tagInfo,
    isEditable,
  } = props;
  const { user: currentUser } = useUserContext();
  const { addToast } = useToastContext();

  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState("");
  const [companyTags, setCompanyTags] = useState([]);
  const [currentTags, setCurrentTags] = useState([]);

  useEffect(() => {
    if (user && user.tags) {
      setCurrentTags([...user.tags]);
    } else if (device && device.tags) {
      setCurrentTags([...device.tags]);
    }
  }, [user, device]);

  const getCompanyTags = useCallback(async () => {
    let response;

    if (device) {
      response = await currentUser.api.getTagsDevice();
    } else if (user || currentUser) {
      response = await currentUser.api.getTagsUser();
    }
    setCompanyTags(response.data);
  }, [device, user, currentUser.api]);

  const onInputType = (e) => {
    setSearchInput(e.target.value);
  };

  const onSelectTag = (tag) => {
    if (typeof isChanging === "function") isChanging(true);

    if (tag !== tagInfo) {
      const newTags = [...currentTags, tag];

      setCurrentTags(newTags);
      setSearchInput("");
      onChange(newTags);
    }
  };

  const onTagCreate = async () => {
    try {
      const tagData = {
        label: searchInput,
        type: user ? "USER" : "DEVICE",
      };
      const newTag = await currentUser.api.addTag(tagData);

      // Add to current tags
      onSelectTag(newTag.data);
      if (typeof isChanging === "function") isChanging(true);
      // Reload tag list
      getCompanyTags();
    } catch (err) {
      addToast(t(err.response.data.message));
    }
  };

  const onRemove = (tag) => {
    const newTags = currentTags.filter((item) => item.id !== tag.id);

    setCurrentTags(newTags);
    onChange(newTags);
    if (typeof isChanging === "function") isChanging(true);
  };

  useEffect(() => {
    if (currentUser.isAdmin()) {
      getCompanyTags();
    }
  }, []);

  const availableTags = companyTags
    // Filter via search
    .filter(
      (t) => searchInput === ""
        || t.label.toLowerCase().startsWith(searchInput.toLowerCase()),
    )
    // Exclude currentTags
    .filter((t) => !currentTags.find((tag) => tag.id === t.id));

  const onInputEnter = (e) => {
    if (
      e.key === "Enter"
      && availableTags.length === 0
      && searchInput.length > 1
    ) {
      onTagCreate();
    }
  };

  return (
    <Layout spacing={1} fullWidth>
      <Layout
        direction="row"
        spacing={1}
        flexWrap="wrap"
        data-cy="selectedTags"
      >
        {currentTags?.map((item) => (
          <Tag
            backgroundColor={item.color}
            variant="filled"
            icon={faTimes}
            onIconClick={() => onRemove(item)}
            label={item.label}
            size="small"
          />
        ))}
      </Layout>
      <Layout direction="row" spacing={1} fullWidth>
        <TextField
          placeholder={t(user ? "Ajouter des équipes" : "Ajouter des tags")}
          type="search"
          value={searchInput}
          onChange={onInputType}
          onKeyDown={onInputEnter}
          sx={{ width: "100%" }}
        />
        {availableTags.length === 0 && searchInput.length > 1 && (
          <Button
            variant="contained"
            color="secondary"
            label={t("Créer")}
            onClick={onTagCreate}
          />
        )}
      </Layout>
      <TagSelectAttribution
        tagsList={availableTags}
        onSelectTag={onSelectTag}
        toggleMenu={toggleMenu}
        setTagInfo={setTagInfo}
        isEditable={isEditable}
      />
    </Layout>
  );
};

export default TagsAttribution;

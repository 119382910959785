import React from "react";

import { useUserContext } from "src/contexts/UserContext";

import SpecialRoute from "src/components/routing/SpecialRoute";

const CockpitRoute = (props) => {
  const {
    component: Component, otherCountryAccess, onlyMdmEmployee, requiresAdmin, ...rest
  } = props;
  const { user: currentUser } = useUserContext();

  const accessControl = () => {
    if (!currentUser || !currentUser.isLoaded()) {
      // No user
      return { isAuthorized: false, redirectTo: "/login" };
    }

    if (!currentUser.isCompanyOnboarded()) {
      // Company not onboarded: registration page
      return { isAuthorized: false, redirectTo: `/register?email=${currentUser.email}` };
    }

    if (requiresAdmin && !currentUser.isAdmin()) {
      // Page only available to admin
      // Back to cockpit home
      return { isAuthorized: false, redirectTo: "/" };
    }

    if (onlyMdmEmployee && !currentUser.company.company_mdm && !currentUser.isAdmin()) {
      // Page only available to MDM company

      // Back to cockpit home
      return { isAuthorized: false, redirectTo: "/devices" };
    }

    if (!currentUser.email) {
      return { isAuthorized: false, redirectTo: "/complete-your-info" };
    }

    return { isAuthorized: currentUser.canAccessCockpit() };
  };

  return (
    <SpecialRoute
      {...rest}
      accessControl={accessControl}
      component={Component}
    />
  );
};

export default CockpitRoute;

/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { faChevronRight, faLaptopMobile } from "@fortawesome/pro-regular-svg-icons";
import {
  Avatar,
  Heading,
  Icon,
  Layout,
  Separator,
  Text,
  theme,
} from "tarmac-v3";
import { useToastContext } from "src/contexts/ToastContext";
import { useUserContext } from "src/contexts/UserContext";
import { useCatalogContext } from "src/contexts/CatalogContext";
import ProductGroupCardNarrow from "./ProductGroupCardNarrow";

const ShoppingCartModalEmpty = ({ onClose }) => {
  const { t } = useTranslation();
  const { addToast } = useToastContext();
  const { user } = useUserContext();
  const { productGroups } = useCatalogContext();
  const [bestSellerProducts, setBestSellerProducts] = useState([]);
  const fastShippingProductGroups = productGroups.filter((productGroup) => productGroup.products.some((product) => product.max_shipping && parseInt(product.max_shipping, 10) <= 4)).slice(0, 4);

  const getBestSellers = async () => {
    try {
      const response = await user.api.getBestSellers();

      setBestSellerProducts(response.data);
    } catch (error) {
      addToast(t("Une erreur est survenue"));
    }
  };

  useEffect(() => {
    getBestSellers();
  }, []);

  return (
    <Layout direction="column" spacing={4} divider={<Separator orientation="horizontal" />}>
      <Layout display="flex" direction="column" alignItems="center " spacing={2}>
        <Avatar
          icon={<Icon icon={faLaptopMobile} size="M" color="darkGreen" />}
          size={56}
          color={theme.palette.green[100]}
          border
        />
        <Heading variant="h5">
          {t("Vous n’avez pas encore d’équipements dans votre panier !")}
        </Heading>
        <Text variant="body1">
          {t("Consultez le catalogue si vous souhaitez commander un nouvel appareil.")}
        </Text>
      </Layout>
      <Layout display="flex" direction="column" spacing={2}>
        <Text variant="body1" bold>{t("Nos produits populaires")}</Text>
        {
          bestSellerProducts?.length > 0 && (
            <Layout position="relative">
              <Layout
                direction="row"
                wrap="wrap"
                spacing={2}
                isScrollable
                justifyContent="space-between"
              >
                {bestSellerProducts.map((productGroup, index) => (
                  <ProductGroupCardNarrow
                    productGroup={productGroup}
                    index={index}
                    onClose={onClose}
                  />
                ))}
              </Layout>
              <Layout
                position="absolute"
                top="50%"
                right="5px"
              >
                <Avatar
                  icon={<Icon icon={faChevronRight} size="S" />}
                  size={32}
                  color="white"
                  border
                />
              </Layout>
            </Layout>
          )
        }

        <Layout direction="column" marginTop={4}>
          {fastShippingProductGroups.length > 0 && (
            <Layout
              direction="column"
              spacing={2}
            >
              <Text variant="body1" bold>{t("En livraison rapide")}</Text>
              <Layout direction="row" position="relative">
                <Layout
                  direction="row"
                  fullWidth
                  spacing={2}
                  isScrollable
                >
                  {fastShippingProductGroups.map((productGroup, index) => (
                    <ProductGroupCardNarrow
                      productGroup={productGroup}
                      index={index}
                      onClose={onClose}
                      showFastShippingTag
                    />
                  ))}
                </Layout>
                <Layout
                  direction="column"
                  position="absolute"
                  top="50%"
                  right="5px"
                >
                  <Avatar
                    icon={<Icon icon={faChevronRight} size="S" />}
                    size={32}
                    color="white"
                    border
                  />
                </Layout>
              </Layout>
            </Layout>
          )}
        </Layout>
      </Layout>
    </Layout>
  );
};

export default ShoppingCartModalEmpty;

export const getInitials = (user) => `${user?.firstName ? user.firstName[0] : ""}${user?.lastName ? user.lastName[0] : ""}`.toUpperCase();

export const getArticleLanguageCode = (user) => (["en", "de"].includes(user.language) ? "en-gb" : user.language);

export const applyTagsFilters = (users, filters) => users.filter((user) => {
  if (filters.tags.length === 0) {
    return true;
  }

  if (filters.tags.includes("NONE") && user.tags.length === 0) {
    return true;
  }

  const deviceTagIds = user.tags.map((tag) => tag.id);

  if (filters.tags.some((tagId) => deviceTagIds.includes(tagId))) {
    return true;
  }

  return false;
});

export const applyNameFilters = (users, filters) => users.filter((user) => {
  const userFullName = `${user.firstName} ${user.lastName}`;

  if (filters.name.length === 0) {
    return true;
  }

  if (filters.name.includes("NONE") && (!userFullName)) {
    return true;
  }

  if (filters.name.includes(userFullName)) {
    return true;
  }

  return false;
});

export const applyMailFilters = (users, filters) => users.filter((user) => {
  if (filters.mail.length === 0) {
    return true;
  }

  if (filters.mail.includes("NONE") && !user.email) {
    return true;
  }

  if (filters.mail.includes(user.email)) {
    return true;
  }

  return false;
});

export const applyUsedDevicesFilters = (users, filters) => users.filter((user) => {
  if (filters.used_devices.length === 0) {
    return true;
  }

  if (!user.used_devices) {
    return true;
  }

  if (filters.used_devices.includes(user.used_devices.length)) {
    return true;
  }

  return false;
});

export const applyStatusFilters = (employees, filters) => employees.filter((employee) => {
  const thirtyDaysLater = new Date(Date.now() + 30 * 24 * 60 * 60 * 1000);
  const thirtyDaysAgo = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);

  const employeesWithArrivalDate = new Date(employee.arrival_date) >= new Date() && new Date(employee.arrival_date) <= thirtyDaysLater;
  const employeesWithoutArrivalDate = !employee.arrival_date && new Date(employee.created_at) <= new Date() && new Date(employee.created_at) >= thirtyDaysAgo;
  const newArrivalEmployees = employeesWithArrivalDate + employeesWithoutArrivalDate;
  const currentEmployees = employee.departure_date
    ? (new Date(employee.departure_date) > new Date() && new Date(employee.arrival_date) < new Date())
    : true;
  const nextDepartures = employee.departure_date ? new Date(employee.departure_date) >= new Date() && new Date(employee.departure_date) <= thirtyDaysLater : false;

  if (filters.status.length === 0) {
    return true;
  }

  if (filters.status.includes("NEW_ARRIVAL") && newArrivalEmployees) {
    return true;
  }

  if (filters.status.includes("CURRENT") && currentEmployees) {
    return true;
  }

  if (filters.status.includes("NEXT_DEPARTURE") && nextDepartures) {
    return true;
  }

  return false;
});

import axios from "axios";

class ApiService {
  constructor(user) {
    let baseUrl = "";

    // PR Number given : Query Preview URL
    if (process.env.REACT_APP_PR_NUMBER) {
      baseUrl = `https://fleet-co-pr-${process.env.REACT_APP_PR_NUMBER}.herokuapp.com/v1`;
    } else {
      baseUrl = process.env.REACT_APP_APIURL;
    }

    baseUrl += `/company/${user.company_id}`;

    const accessToken = window.localStorage.getItem("accessToken");

    this.axios = axios.create({
      baseURL: baseUrl,
      ...(accessToken && {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }),
    });
  }

  /* ---------- COMPANY ---------- */
  getCompany = () => this.axios.get("");

  modifyCompany = (params) => this.axios.put("", params);

  modifyCompanyLogo = (params) => this.axios.patch("/image", params);

  getCompanySignatory = () => this.axios.get("/signatory");

  getCompanyAchievements = () => this.axios.get("/achievements");

  checkCompanySelfEmployeed = () => this.axios.patch("/check_self_employeed");

  /* ---------- MDM ---------- */

  getMdmDashboard = () => this.axios.get("/mdm");

  modifyCompanyMdm = (params) => this.axios.patch("/mdm", params);

  subscribeMdm = (devicesData) => this.axios.post("/mdm/subscribe", devicesData);

  unsubscribeMdm = () => this.axios.delete("/mdm/unsubscribe");

  addDevicesToMdm = (devicesData) => this.axios.post("/mdm/add-devices", devicesData);

  removeDevicesFromMdm = (devicesData) => this.axios.post("/mdm/remove-devices", devicesData);

  getMdmInvoices = () => this.axios.get("/mdm/invoices");

  createDraftDevicesMdm = (devicesData) => this.axios.post("/mdm/add-draft-devices", devicesData);

  batchCreateSubscribedDevicesMdm = (devicesData) => this.axios.post("/mdm/add-subscribed-devices", devicesData);

  getDraftDevicesMdm = () => this.axios.get("/mdm/draft-devices");

  batchSendMdmInstallationReminder = () => this.axios.post("/mdm/batch/send-enrollment-reminder");

  activateDiskEncryptionBatch = () => this.axios.post("/mdm/batch/commands/activateDiskEncryption");

  activateFirewallBatch = () => this.axios.post("/mdm/batch/commands/activateFirewall");

  activateScreenLockBatch = () => this.axios.post("/mdm/batch/commands/activateScreenLock");

  activateOsUpdatesBatch = () => this.axios.post("/mdm/batch/commands/activateOsUpdates");

  resyncMdmDevicesBatch = () => this.axios.post("/mdm/batch/sync");

  /* ---------- MDM APPS ---------- */

  addAppToCart = (appId) => this.axios.post(`/mdm_app/add_to_cart/${appId}`);

  confirmDeployment = (apps) => this.axios.post("/mdm_app/confirm_deployment", apps);

  removeAppFromCart = (appId) => this.axios.post(`/mdm_app/remove_from_cart/${appId}`);

  /* ---------- ADDRESS ---------- */
  getAddresses = () => this.axios.get("/address");

  getAddress = (addressId) => this.axios.get(`/address/${addressId}`);

  addAddress = (params) => this.axios.post("/address", params);

  makeAddressDefault = (addressId) => this.axios.patch(`/address/${addressId}/default`);

  modifyAddress = (addressId, params) => this.axios.put(`/address/${addressId}`, params);

  createOrUpdateAddress = (address) => {
    if (address.id) {
      return this.modifyAddress(address.id, address);
    }

    return this.addAddress(address);
  };

  deleteAddress = (addressId) => this.axios.delete(`/address/${addressId}`);

  /* ---------- DEVICES ---------- */

  createExternalDevice = (device) => this.axios.post("/device", device);

  batchCreateExternalDevices = (devicesData) => this.axios.post("/device/batch_create", devicesData);

  batchImportExternalDevices = (batchInfos) => this.axios.post("/device/batch_import", batchInfos);

  deleteExternalDevice = (deviceId) => this.axios.delete(`/device/${deviceId}`);

  deleteExternalDevices = (deviceIds) => {
    const params = { data: { devices: deviceIds } };

    return this.axios.delete("/device", params);
  };

  getDevices = () => this.axios.get("/device");

  getDevice = (deviceId) => this.axios.get(`/device/${deviceId}`);

  getDeviceTrackingInfo = (deviceId) => this.axios.get(`/device/${deviceId}/tracking_info`);

  modifyDevice = (deviceId, params) => this.axios.put(`/device/${deviceId}`, params);

  getDevicesActivities = () => this.axios.get("/devices/activities");

  getReturnLabel = (parcelId) => this.axios.post(`/device/parcel/${parcelId}`);

  shutdownDevice = (deviceId) => this.axios.post(`/device/${deviceId}/mdm/commands/shutdown`);

  rebootDevice = (deviceId) => this.axios.post(`/device/${deviceId}/mdm/commands/reboot`);

  wipeDevice = (deviceId, params) => this.axios.post(`/device/${deviceId}/mdm/commands/wipe`, params);

  sendCodeWipeEmail = (deviceId, params) => this.axios.post(`/device/${deviceId}/mdm/send-wipe-code`, params);

  lockDevice = (deviceId, code) => this.axios.post(`/device/${deviceId}/mdm/commands/lock`, { code });

  activateDiskEncryptionDevice = (deviceId) => this.axios.post(`/device/${deviceId}/mdm/commands/activateDiskEncryption`);

  deactivateDiskEncryptionDevice = (deviceId) => this.axios.post(`/device/${deviceId}/mdm/commands/deactivateDiskEncryption`);

  activateOsUpdatesDevice = (deviceId) => this.axios.post(`/device/${deviceId}/mdm/commands/activateOsUpdates`);

  deactivateOsUpdatesDevice = (deviceId) => this.axios.post(`/device/${deviceId}/mdm/commands/deactivateOsUpdates`);

  activateFirewallDevice = (deviceId) => this.axios.post(`/device/${deviceId}/mdm/commands/activateFirewall`);

  deactivateFirewallDevice = (deviceId) => this.axios.post(`/device/${deviceId}/mdm/commands/deactivateFirewall`);

  activateScreenLockDevice = (deviceId) => this.axios.post(`/device/${deviceId}/mdm/commands/activateScreenLock`);

  deactivateScreenLockDevice = (deviceId) => this.axios.post(`/device/${deviceId}/mdm/commands/deactivateScreenLock`);

  updateDeviceMdm = (deviceId, params) => this.axios.patch(`/device/${deviceId}/mdm`, params);

  deviceMdmSync = (deviceId) => this.axios.post(`/device/${deviceId}/mdm/sync`);

  sendDeviceMdmEnrollmentReminderEmail = (deviceId) => this.axios.post(`/device/${deviceId}/mdm/send-enrollment-reminder`);

  getDeviceMdmSecurityCodes = (deviceId) => this.axios.get(`/device/${deviceId}/mdm/security-codes`);

  resetDeviceMdm = (deviceId) => this.axios.post(`/device/${deviceId}/mdm/reset`);

  getDeviceApps = (deviceId) => this.axios.get(`/device/${deviceId}/apps`);

  /* ---------- ORDERS ---------- */

  getOrders = () => this.axios.get("/order");

  getValidatedOrders = () => this.axios.get("/order/validated");

  getVisibleInCockpitOrders = () => this.axios.get("/order/visible_in_cockpit");

  getOngoingOrder = () => this.axios.get("/order/ongoing");

  getOrder = (orderId) => this.axios.get(`/order/${orderId}`);

  validateOrder = (orderId, params) => this.axios.patch(`/order/${orderId}/validate`, params);

  modifyOrder = (orderId, params) => this.axios.patch(`/order/${orderId}`, params);

  modifyOrderDevice = (orderId, params) => this.axios.patch(`/order/${orderId}/device`, params);

  addDeviceToOrder = (orderId, params) => this.axios.patch(`/order/${orderId}/add_device`, params);

  addShoppingListToOrder = (orderId, params) => this.axios.patch(`/order/${orderId}/add_shopping_list`, params);

  removeDeviceFromOrder = (orderId, params) => this.axios.patch(`/order/${orderId}/remove_device`, params);

  deleteOrderProduct = (orderId, params) => this.axios.patch(`/order/${orderId}/delete_product`, params);

  clearOrder = (orderId) => this.axios.patch(`/order/${orderId}/clear`);

  getRenewalBilling = (orderId) => this.axios.get(`/order/${orderId}/renewal_billing`);

  confirmRenewalDecision = (orderId, paymentData) => this.axios.post(`/order/${orderId}/confirm_renewal_decision`, paymentData);

  generateReturnLabel = (deviceId) => this.axios.post(`/device/${deviceId}/return-dropoff`);

  getOldRenewalBilling = (orderId) => this.axios.post(`/order/${orderId}/old_buyback_invoice`, null, { responseType: "blob" });

  /* ---------- TICKETS ---------- */
  getTickets = () => this.axios.get("/ticket");

  getTicket = (ticketId) => this.axios.get(`/ticket/${ticketId}`);

  addTicket = (params) => this.axios.post("/ticket", params);

  modifyTicket = (ticketId, params) => this.axios.patch(`/ticket/${ticketId}`, params);

  addTicketComment = (ticketId, params) => this.axios.post(`ticket/${ticketId}/comment`, params);

  addSatisfactionRating = (ticketId, params) => this.axios.post(`ticket/${ticketId}/satisfaction_rating`, params);

  /* ---------- USERS ---------- */
  getUsers = () => this.axios.get("/user");

  getUser = (userId) => this.axios.get(`/user/${userId}`);

  resetPassword = (userId, params) => this.axios.patch(`/user/${userId}/password`, params);

  addUser = (params) => this.axios.post("/user", params);

  checkUser = (params) => this.axios.post("/user/check", params);

  changePreferences = (userId, params) => this.axios.patch(`/user/${userId}/preferences`, params);

  batchImportUsers = (batchInfos) => this.axios.post("/user/batch_import", batchInfos);

  batchImportUsersSirh = (batchInfos) => this.axios.post("/user/sirh_batch_import", batchInfos);

  modifyUser = (userId, params) => this.axios.put(`/user/${userId}`, params);

  createOrUpdateUser = (user) => {
    if (user.id) {
      return this.modifyUser(user.id, user);
    }

    return this.addUser(user);
  };

  sendAdminAccess = (userId, params) => this.axios.patch(`/user/admin_invitation/${userId}`, params);

  deleteUser = (userId) => this.axios.delete(`/user/${userId}`);

  deleteUsers = (userIds) => {
    const params = { data: { users: userIds } };

    return this.axios.delete("/user", params);
  };

  getUserMagicLink = (userId) => this.axios.get(`/user/${userId}/magic_link`);

  /* ---------- FILES (COMPANY LEVEL) ---------- */
  getFiles = () => this.axios.get("/file");

  getFile = (fileId) => this.axios.get(`/file/${fileId}`, { responseType: "blob" });

  addFile = (params) => this.axios.post("/file/", params);

  modifyFile = (fileId, params) => this.axios.put(`/file/${fileId}`, params);

  deleteFile = (fileId) => this.axios.delete(`/file/${fileId}`);

  /* ---------- FILES (ORDER LEVEL) ---------- */
  getOrderFiles = (orderId) => this.axios.get(`/order/${orderId}/download_archive`, { responseType: "blob" });

  /* ---------- TAGS (COMPANY LEVEL) ---------- */
  getTags = () => this.axios.get("/tag");

  getTagsUser = () => this.axios.get("/tag/users");

  getTagsDevice = () => this.axios.get("/tag/devices");

  addTag = (data) => this.axios.post("/tag", data);

  updateTag = (tagId, data) => this.axios.put(`/tag/${tagId}`, data);

  deleteTag = (tagId) => this.axios.delete(`/tag/${tagId}`);

  /* ---------- TAGS (ITEM LEVEL) ---------- */
  getDeviceTags = (deviceId) => this.axios.get(`/tag/${deviceId}`);

  getUserTags = (userId) => this.axios.get(`/tag/user/${userId}`);

  /* ---------- PAYMENT ---------- */
  createPaymentIntent = (paymentData) => this.axios.post("/payment", paymentData);

  getPaymentMethods = () => this.axios.get("/payment_method");

  createPaymentMethod = (paymentMethodData) => this.axios.post("/payment_method", paymentMethodData);

  cancelInsurance = (orderId) => this.axios.post(`/order/${orderId}/insurance_cancel`);

  createEvySubscription = (orderId) => this.axios.post(`/order/${orderId}/create_evy_subscription`);

  subscribeToFleetInsurance = (orderId) => this.axios.post(`/order/${orderId}/start_fleet_insurance`);

  subscribeToFleetInsuranceForMassEdit = (orderIdList) => this.axios.post("/order/mass_start_fleet_insurance", { orderIdList });

  getStripeInvoice = (orderId, type) => this.axios.get(`/order/${orderId}/invoices?type=${type}`, { type });

  getInvoices = (type) => this.axios.get(`/invoices?type=${type}`);

  createCheckoutSession = (pathname) => this.axios.post("/checkout-session", { pathname });

  /* ---------- FEATURE FLAGS ---------- */
  getFeatureFlags = () => this.axios.get("/feature_flags");

  /* ---------- SHOPPING LISTS ---------- */
  getShoppingLists = () => this.axios.get("/shopping_list");

  createShoppingList = (data) => this.axios.post("/shopping_list", data);

  updateShoppingList = (shoppingListId, data) => this.axios.put(`/shopping_list/${shoppingListId}`, data);

  addProductToShoppingList = (shoppingListId, params) => this.axios.patch(`/shopping_list/${shoppingListId}/add_product`, params);

  removeProductFromShoppingList = (shoppingListId, params) => this.axios.patch(`/shopping_list/${shoppingListId}/remove_product`, params);

  deleteShoppingList = (shoppingListId) => this.axios.delete(`/shopping_list/${shoppingListId}`);

  /* ---------- Offers ---------- */
  addNewOfferToCompany = (params) => this.axios.post("/offer", params);

  modifyOffer = (offerId, offerInfos) => this.axios.put(`/offer/${offerId}`, offerInfos);

  /* ---------- Integration ---------- */
  activateIntegration = (integrationSlug) => this.axios.post(`/integration/activate/${integrationSlug}`);

  syncActivation = (integrationSlug) => this.axios.put(`/integration/sync/${integrationSlug}`);

  deactivateIntegration = (integrationSlug) => this.axios.put(`/integration/deactivate/${integrationSlug}`);

  logPersonioUser = (dataPersonio) => this.axios.post("/integration/personio/login", dataPersonio);

  /* ---------- TRACKING ------------ */
  // No side-effects, no need to await
  trackEvent = (eventName) => this.axios.post("/track_event", { eventName });

  /* ---------- BEST SELLERS ------------ */
  getBestSellers = () => this.axios.get("/best_sellers");
}

export default ApiService;

const getLocalePlaceholder = (country, type) => {
  let countryName = "fr";

  // Most of the time this function get country but in the registration form we can only get a language
  switch (country) {
    case "fr":
      countryName = "france";
      break;
    case "de":
      countryName = "germany";
      break;
    case "es":
      countryName = "spain";
      break;
    case "en":
      countryName = "united kingdom";
      break;
    default:
      countryName = country || "france";
  }

  const placeholdersDictionary = {
    address1: {
      france: "15 rue de la paix",
      germany: "Pappelallee 78/79",
      spain: "Calle de la Paz 15",
      "united kingdom": "483 Green Lanes",
      other: "Rechercher une adresse",
    },
    address2: {
      france: "3ème étage",
      germany: "3. Etage",
      spain: "3er piso",
      "united kingdom": "3rd floor",
      other: "Building 3",
    },
    zip: {
      france: "75009",
      germany: "10437",
      spain: "08001",
      "united kingdom": "N13 4BS",
      other: "SW1A 1AA",
    },
    city: {
      france: "Paris",
      germany: "Berlin",
      spain: "Madrid",
      "united kingdom": "London",
      other: "Bruxelles",
    },
    country: {
      france: "France",
      germany: "Deutschland",
      spain: "España",
      "united kingdom": "United Kingdom",
      other: "Belgique",
    },
    firstName: {
      france: "Jean",
      germany: "Thomas",
      spain: "Maria",
      "united kingdom": "Harry",
    },
    lastName: {
      france: "Dupont",
      germany: "Müller",
      spain: "García",
      "united kingdom": "Kinsley",
    },
    email: {
      france: "jean@entreprise.com",
      germany: "thomas@enterprise.de",
      spain: "maria@entreprise.es",
      "united kingdom": "harry@entreprise.uk",
    },
  };

  const fleetCountries = ["france", "germany", "spain", "united kingdom", "other"];
  const usedCountry = fleetCountries.includes(countryName.toLowerCase()) ? countryName.toLowerCase() : "france";

  return placeholdersDictionary[type][usedCountry];
};

export default getLocalePlaceholder;

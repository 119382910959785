import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Label,
  LabeledTextField,
  Modal,
  VerticalLayout,
} from "tarmac-v2";

import PhoneInput from "src/components/utils/PhoneInput";
import { useUserContext } from "src/contexts/UserContext";
import { useToastContext } from "src/contexts/ToastContext";

const CreateEmployeeModal = (props) => {
  // PROPS & CONTEXT
  const { onSave, onClose } = props;
  const { user: currentUser } = useUserContext();
  const { addToast } = useToastContext();
  const { t } = useTranslation();

  // INITIALISATION STATES & VARIABLES
  const [creatingUser, setCreatingUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    phone_country: "",
  });
  const [isPhoneValid, setIsPhoneValid] = useState(false);

  const onInputChange = useCallback((e) => {
    const { name, value } = e.target;

    setCreatingUser({ ...creatingUser, [name]: value });
  }, [creatingUser]);

  const onPhoneChange = useCallback((phone, country) => {
    setCreatingUser({ ...creatingUser, phone, phone_country: country });
  }, [creatingUser]);

  const createUser = useCallback(async () => {
    if (!creatingUser.firstName.trim() || !creatingUser.lastName.trim() || !creatingUser.email.trim()) {
      const missingFields = [
        !creatingUser.firstName.trim() && "Prénom",
        !creatingUser.lastName.trim() && "Nom",
        !creatingUser.email.trim() && "E-Mail"]
        .filter(Boolean);

      addToast(t(`Veuillez renseigner tous les champs requis : ${missingFields.join(", ")}`));

      return;
    }

    if (!creatingUser.email.includes("@")) {
      addToast(t("Veuillez renseigner un email valide"));

      return;
    }

    try {
      await currentUser.api.checkUser({ email: creatingUser.email });
    } catch (err) {
      if (err.response.status === 409) {
        addToast(t("Un utilisateur avec cette addresse mail existe déjà"));

        return;
      }

      addToast(t("Une erreur est survenue"));

      return;
    }

    if (creatingUser.phone && !isPhoneValid) {
      addToast(t("Le numéro de téléphone n'est pas valide"));

      return;
    }

    try {
      const userProps = {
        firstName: creatingUser.firstName,
        lastName: creatingUser.lastName,
        email: creatingUser.email.trim(),
        phone: creatingUser.phone,
        phone_country: creatingUser.phone_country,
        roles_add: "EMPLOYEE",
      };

      const apiResponse = await currentUser.api.addUser(userProps);
      const userId = apiResponse.data.id;

      onSave(userId);
      onClose();
    } catch (err) {
      console.error(err);
      addToast(t(err.response.data.message) || t("Action impossible à effectuer"));
    }
  }, [addToast, creatingUser, currentUser.api, isPhoneValid, onClose, onSave, t]);

  return (
    <Modal
      closeModal={onClose}
      title={t("Créer un employé")}
      primaryButton={(
        <Button fullWidth onClick={createUser}>
          {t("Enregistrer")}
        </Button>
      )}
      secondaryButton={(
        <Button fullWidth onClick={onClose}>
          {t("Annuler")}
        </Button>
      )}
    >
      <VerticalLayout gap={16}>
        <LabeledTextField
          onChange={onInputChange}
          name="firstName"
          value={creatingUser.firstName}
          indication="*"
        >
          {t("Prénom")}
        </LabeledTextField>
        <LabeledTextField
          onChange={onInputChange}
          name="lastName"
          value={creatingUser.lastName}
          indication="*"
        >
          {t("Nom")}
        </LabeledTextField>
        <LabeledTextField
          onChange={onInputChange}
          name="email"
          value={creatingUser.email}
          indication="*"
        >
          {t("E-Mail")}
        </LabeledTextField>
        <VerticalLayout>
          <Label>{t("Numéro de téléphone")}</Label>
          <PhoneInput
            onChange={onPhoneChange}
            name="phone"
            value={creatingUser.phone}
            country={creatingUser.phone_country}
            setIsPhoneValid={setIsPhoneValid}
          />
        </VerticalLayout>
      </VerticalLayout>
    </Modal>
  );
};

export default CreateEmployeeModal;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  HorizontalLayout,
  LabeledTextField,
  ListCard,
  SectionZone,
  Text,
  VerticalLayout,
} from "tarmac-v2";
import FleetDatePicker from "src/components/utils/DatePicker";
import PlaceService from "src/tools/PlaceService";

const placeService = new PlaceService();

const InsuranceInfoForm = (props) => {
  const { signatoryInfos, onChange } = props;
  const [isSearching, setIsSearching] = useState(true);
  const [shouldAutoFocus, setAutoFocus] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const { t } = useTranslation();

  const [searchQuery, setSearchQuery] = useState("");

  const onSearch = async (value) => {
    setIsSearching(true);
    setAutoFocus(true);
    setSearchQuery(value);
    if (value.length > 2) {
      const suggestionsFromApi = await placeService.getPlaceIdFromText(value, ["(cities)"]);

      if (suggestionsFromApi.length > 0) {
        setSuggestions(suggestionsFromApi);
      }
    }
  };

  const onSelect = async (value) => {
    const { city, countryISO } = await placeService.getAddressForPlaceId(value.place_id);

    onChange("cityOfBirth", city);
    onChange("countryOfBirth", countryISO);
    setSearchQuery(city);
    setIsSearching(false);
  };

  useEffect(() => {
    if (signatoryInfos.cityOfBirth && signatoryInfos.countryOfBirth) {
      setIsSearching(false);
      setSearchQuery(signatoryInfos.cityOfBirth);
    }
  }, [signatoryInfos.cityOfBirth, signatoryInfos.countryOfBirth]);

  return (
    <VerticalLayout gap="8">
      <FleetDatePicker
        dateFormat="dd/MM/yyyy"
        value={signatoryInfos.dateOfBirth ? new Date(signatoryInfos.dateOfBirth) : null}
        onChange={(date) => onChange("dateOfBirth", date)}
      >
        {t("Date de naissance")}
      </FleetDatePicker>
      {isSearching ? (
        <VerticalLayout gap="8">
          <LabeledTextField
            autoFocus={shouldAutoFocus}
            onChange={({ target: { value } }) => onSearch(value)}
            name="placeOfBirth"
            value={searchQuery}
          >
            {t("Lieu de naissance")}
          </LabeledTextField>
          {suggestions.length > 0 && (
            <SectionZone variant="muted" padding="8" color="white">
              <VerticalLayout gap="8">
                {suggestions.map((suggestion) => (
                  <ListCard
                    canHover
                    key={suggestion.place_id}
                    onClick={() => {
                      onSelect(suggestion);
                    }}
                  >
                    <Text size="M">{suggestion.description}</Text>
                  </ListCard>
                ))}
              </VerticalLayout>
            </SectionZone>
          )}
        </VerticalLayout>
      ) : (
        <HorizontalLayout gap="16">
          <LabeledTextField
            onChange={({ target: { value } }) => onSearch(value)}
            name="cityOfBirth"
            value={searchQuery}
          >
            {t("Ville de naissance")}
          </LabeledTextField>
          <LabeledTextField
            name="countryOfBirth"
            disabled
            value={signatoryInfos.countryOfBirth}
          >
            {t("Code pays")}
          </LabeledTextField>
        </HorizontalLayout>
      )}
    </VerticalLayout>
  );
};

export default InsuranceInfoForm;

import React, {
  useEffect, useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  Button, FileDropzone, HorizontalLayout, Icon, Modal, Text, VerticalLayout,
} from "tarmac-v2";

import { useUserContext } from "src/contexts/UserContext";
import { downloadFileBlob, imgExtensions } from "src/tools/FilesHelpers";
import { faLock } from "@fortawesome/pro-regular-svg-icons";
import OldMandateManager from "src/components/cockpit/blocks/billing/OldMandateManager";
import filesNiceName from "../filesNiceName";

const OldCompanyFilesModal = (props) => {
  const {
    onClose,
  } = props;
  const { t } = useTranslation();
  const { user: currentUser, reloadUser } = useUserContext();

  const [files, setFiles] = useState({
    IBAN: null,
  });
  const companyFilesList = ["IBAN"];
  const extraFilesList = ["BALANCE_SHEET"];

  const ukUser = currentUser.company.country === "United Kingdom";
  // const ribDoc = ukUser ? t("Relevé d'identité bancaire") : t("RIB");
  const mandate = ukUser ? t("Débit Direct *") : t("Mandat SEPA *");

  const downloadFile = async (file) => {
    const response = await currentUser.api.getFile(file.id);

    downloadFileBlob(response.data, (file.original_name || file.url));
  };

  const deleteFile = async (file, fileType) => {
    await currentUser.api.deleteFile(file.id);
    setFiles((prevFiles) => ({ ...prevFiles, [fileType]: undefined }));
  };

  const submitFile = async (e, type) => {
    const formData = new FormData();

    formData.append("type", type);
    formData.append("status", "TO_EXAMINE");
    formData.append("file_contents", e.target.files[0]);
    const { data: newFile } = await currentUser.api.addFile(formData);

    setFiles((prevFiles) => ({ ...prevFiles, [type]: newFile }));
    reloadUser();
  };

  useEffect(() => {
    const iban = currentUser.company.files.find((file) => file.type === "IBAN");
    const balanceSheet = currentUser.company.files.find((file) => file.type === "BALANCE_SHEET");

    if (currentUser.company.country !== "France") {
      setFiles({});

      return;
    }
    const requiredFiles = {
      IBAN: iban,
      BALANCE_SHEET: balanceSheet,
    };

    setFiles(requiredFiles);
  }, [currentUser]);

  const modalConfig = {
    title: "Ajouter les pièces justificatives obligatoires",
    mainButton: (
      <Button fullWidth onClick={onClose}>
        {t("Valider")}
      </Button>
    ),
  };

  return (
    <Modal
      closeModal={onClose}
      title={t(modalConfig.title)}
      primaryButton={modalConfig.mainButton}
      secondaryButton={modalConfig.secondaryButton}
    >
      <VerticalLayout gap="16" isScrollable>
        <VerticalLayout gap="8">
          <Text>
            {t("Pour créer votre contract de location, nous devons valider vos informations bancaires. Veuillez noter que vous ne serez pas débité immédiatement, mais uniquement après la validation de votre contrat et la livraison de votre matériel.")}
          </Text>
        </VerticalLayout>
        {Object.keys(files).length > 0 && (
          <VerticalLayout gap={8}>
            <Text size="m" bold>{t("Relevé d'identité bancaire")}</Text>
            <VerticalLayout gap="8">
              {Object.keys(files).map((fileName, i) => (
                !companyFilesList.includes(fileName) ? null
                  : (
                    <FileDropzone
                      key={i}
                      selectFile={(e) => submitFile(e, fileName)}
                      isSmall
                      width="100%"
                      files={files[fileName]}
                      acceptedExtensions={imgExtensions.join(", ")}
                      onDownload={downloadFile}
                      onDelete={(file) => deleteFile(file, fileName)}
                      text={t(filesNiceName[fileName])}
                      label={t(filesNiceName[fileName])}
                    />
                  )
              ))}
            </VerticalLayout>
          </VerticalLayout>
        )}
        <>
          <Text size="m" bold>{mandate}</Text>

          <OldMandateManager onSave={reloadUser} />

          <HorizontalLayout gap={8}>
            <Icon icon={faLock} />
            <Text size="s" muted>{t("Vos moyens de paiements sont sécurisés par Stripe et ne sont pas stockés par Fleet")}</Text>
          </HorizontalLayout>
        </>

        {currentUser?.company.country === "France" && (
          <VerticalLayout gap={8}>
            <Text size="m" bold>{t("Bilan comptable (optionnel)")}</Text>
            <VerticalLayout gap="8">
              {Object.keys(files).map((fileName, i) => (
                !extraFilesList.includes(fileName) ? null
                  : (
                    <>
                      <FileDropzone
                        key={i}
                        selectFile={(e) => submitFile(e, fileName)}
                        isSmall
                        width="100%"
                        files={files[fileName]}
                        acceptedExtensions={imgExtensions.join(", ")}
                        onDownload={downloadFile}
                        onDelete={(file) => deleteFile(file, fileName)}
                        text={t(filesNiceName[fileName])}
                        label={t(filesNiceName[fileName])}
                      />
                      <Text size="s" muted>{t("Ces informations optionnelles permettent de faciliter l'obtention d'un accord de financement. Elles pourront vous être demandées ultérieurement.")}</Text>
                    </>
                  )
              ))}
            </VerticalLayout>
          </VerticalLayout>
        )}
      </VerticalLayout>
    </Modal>
  );
};

export default OldCompanyFilesModal;

import React, {
  useCallback, useEffect, useMemo, useState,
} from "react";
import { useTranslation } from "react-i18next";
import {
  Button, HorizontalLayout, Icon, SectionZone,
  Text,
  VerticalLayout,
} from "tarmac-v2";
import { faPen, faPlus } from "@fortawesome/pro-regular-svg-icons";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useUserContext } from "src/contexts/UserContext";
import StripeFormModal from "src/components/cockpit/renewal/OldStripeFormModal";
import { useLocation } from "react-router-dom";
import { useToastContext } from "src/contexts/ToastContext";

const MandateManager = ({
  setSelectedPaymentMethodId, returnUrl, onSave,
}) => {
  const { user: currentUser } = useUserContext();
  const { t } = useTranslation();
  const { search } = useLocation();
  const { addToast } = useToastContext();
  const { pathname } = useLocation();
  const ukUser = currentUser.company.country === "United Kingdom";

  const [paymentMethod, setPaymentMethod] = useState();
  const [isModalOpen, setModalModalOpen] = useState(false);

  const apiKey = ukUser ? process.env.REACT_APP_STRIPE_API_KEY_UK : process.env.REACT_APP_STRIPE_API_KEY_EU;

  const stripePromise = useMemo(() => loadStripe(apiKey), []);

  const fetchPaymentMethod = useCallback(async () => {
    const { data: paymentMethodsData } = await currentUser.api.getPaymentMethods();
    const activePaymentMethod = paymentMethodsData.find((pm) => pm.is_active);

    if (setSelectedPaymentMethodId) {
      setSelectedPaymentMethodId(activePaymentMethod?.id);
    }

    setPaymentMethod(activePaymentMethod);
  }, [currentUser.api, setSelectedPaymentMethodId]);

  useEffect(() => fetchPaymentMethod(), [isModalOpen]);

  const createCheckoutSession = async () => {
    const session = await currentUser.api.createCheckoutSession(pathname);

    window.open(session.data.session, "_self");
  };

  useEffect(() => {
    const urlQuery = new URLSearchParams(search);
    const successCode = urlQuery.get("success_code");

    const getInfoFromUrl = () => {
      switch (successCode) {
        case "1":
          addToast(t("Votre autorisation de prélèvements a bien été ajoutée"), "success");
          break;
        case "2":
          addToast(t("Votre autorisation de prélèvements a été abandonnée"));
          break;
        default:
          break;
      }
    };

    try {
      getInfoFromUrl();
    } catch (error) {
      console.error(error);
    }
  }, [search]);

  return (
    <VerticalLayout gap="8">

      {!ukUser && (
        paymentMethod ? (
          <SectionZone variant="action">
            <HorizontalLayout justifyContent="space-between" alignItems="start">
              <VerticalLayout>
                <Text bold variant="action">{paymentMethod?.name}</Text>
                <Text muted>{`**** **** **** **** **** ${paymentMethod?.last4}`}</Text>
              </VerticalLayout>
              <Icon icon={faPen} size="S" variant="action" onClick={() => setModalModalOpen(true)} />
            </HorizontalLayout>
          </SectionZone>
        ) : (
          <Button variant="secondary" onClick={() => setModalModalOpen(true)}>
            <Icon icon={faPlus} size="s" />
            {t("Ajouter mandat SEPA")}
          </Button>
        )
      )}

      {ukUser && (
        paymentMethod ? (
          <VerticalLayout gap="8">
            <SectionZone variant="action">
              <HorizontalLayout justifyContent="space-between" alignItems="start">
                <VerticalLayout>
                  <Text bold variant="action">{paymentMethod?.name}</Text>
                  <HorizontalLayout gap={32}>
                    <VerticalLayout>
                      {t("Code guichet")}
                      <br />
                      {paymentMethod?.bacs_sort_code}
                    </VerticalLayout>
                    <VerticalLayout>
                      {t("Numéro de compte")}
                      <br />
                      {`***${paymentMethod?.last4}`}
                    </VerticalLayout>
                  </HorizontalLayout>
                </VerticalLayout>
                <Icon icon={faPen} size="S" variant="action" onClick={createCheckoutSession} />
              </HorizontalLayout>
            </SectionZone>
          </VerticalLayout>
        ) : (
          <Button variant="secondary" onClick={createCheckoutSession}>
            <Icon icon={faPlus} size="s" />
            {t("Mise en place d'un Débit Direct")}
          </Button>
        )
      )}

      <Elements stripe={stripePromise}>
        {isModalOpen && <StripeFormModal setOpen={setModalModalOpen} onSuccess={onSave} returnUrl={returnUrl} />}
      </Elements>
    </VerticalLayout>

  );
};

export default MandateManager;

import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Layout, Link, Text } from "tarmac-v3";

import useLocalStorage from "src/hooks/useLocalStorage";
import { useUserContext } from "src/contexts/UserContext";

const HasAccountAlready = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [, , clearAccessToken] = useLocalStorage("accessToken");
  const { reloadUser } = useUserContext();

  const goToLogin = useCallback(async () => {
    clearAccessToken();
    await reloadUser();
    history.push("/login");
  }, [clearAccessToken, reloadUser, history]);

  return (
    <Layout direction="row" spacing={2} justifyContent="center">
      <Text variant="body1">{t("Déjà un compte ?")}</Text>
      <Link variant="body1" color="darkGreen" label={t("Se connecter")} onClick={goToLogin} iconRight />
    </Layout>
  );
};

export default HasAccountAlready;

import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Avatar, HorizontalLayout, SectionZone, Text,
} from "tarmac-v2";

import { faBoxArchive, faUserLargeSlash } from "@fortawesome/pro-regular-svg-icons";

import { useUserContext } from "src/contexts/UserContext";
import EmployeeClickableCard from "./EmployeeClickableCard";

import styles from "./DeviceUserClickableCard.module.scss";

const DeviceUserClickableCard = ({ device }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { user: currentUser } = useUserContext();

  const viewEmployeeDetail = (userId) => {
    history.push(`/employees/${userId}`);
  };

  if (device.user) {
    return (
      <EmployeeClickableCard
        employee={device.user}
        buttonType={currentUser.isAdmin() ? "link" : null}
        buttonLabel={t("Voir en détails")}
        onButtonClick={() => viewEmployeeDetail(device.user.id)}
        onClick={() => viewEmployeeDetail(device.user.id)}
        style={{ padding: 24 }}
      />
    );
  }

  if (device.usage_status === "OUT_OF_SERVICE") {
    return (
      <HorizontalLayout gap="16" className={styles.noUserCard}>
        <Avatar icon={faBoxArchive} />
        <Text muted size="s">
          {t("Remettez cet appareil en service pour l’attribuer à un employé")}
        </Text>
      </HorizontalLayout>
    );
  }

  if (device.usage_status === "SPARE") {
    return (
      <SectionZone padding="0">
        <HorizontalLayout gap="16" className={styles.noUserCard}>
          <Avatar icon={faBoxArchive} />
          <Text muted size="s">
            {t("Cet équipement est en réserve")}
          </Text>
        </HorizontalLayout>
      </SectionZone>
    );
  }

  return (
    <SectionZone padding="0">
      <HorizontalLayout gap="16" className={styles.noUserCard}>
        <Avatar icon={faUserLargeSlash} />
        <Text muted size="s">
          {t("Cet équipement n’est attribué à aucun employé")}
        </Text>
      </HorizontalLayout>
    </SectionZone>
  );
};

export default DeviceUserClickableCard;

import { productGroupMatches } from "src/tools/SearchFilters";

const filterProductsByWeight = (productGroups, weight) => {
  if (weight === "feather") {
    return productGroups.filter((g) => g.products.some((p) => p.weight < 1100));
  }

  if (weight === "light") {
    return productGroups.filter((g) => g.products.some((p) => p.weight >= 1100 && p.weight < 2100));
  }

  if (weight === "medium") {
    return productGroups.filter((g) => g.products.some((p) => p.weight >= 2100 && p.weight < 2500));
  }

  if (weight === "heavy") {
    return productGroups.filter((g) => g.products.some((p) => p.weight >= 2500));
  }

  return productGroups;
};

const filterOSValuesByDeviceCategory = (category) => {
  switch (category) {
    case "COMPUTER":
      return [
        { label: "Windows", value: "windows", translate: false },
        { label: "MacOS", value: "macos", translate: false },
        { label: "Linux", value: "linux", translate: false },
      ];
    case "TABLET":
      return [
        { label: "iPadOS", value: "ipados", translate: false },
        { label: "Android", value: "android", translate: false },
        { label: "Windows", value: "windows", translate: false },
      ];
    case "SMARTPHONE":
      return [
        { label: "iOS", value: "ios", translate: false },
        { label: "Android", value: "android", translate: false },
        { label: "Windows", value: "windows", translate: false },
      ];
    default:
      return [];
  }
};

const filterProductGroups = (filters, productGroups, currentCurrency) => {
  let newFilteredProductGroups = [...productGroups];
  const filterDbKey = {
    brands: "brand",
    types: "equipment_sub_type",
    screenSizes: "screen_size",
    memories: "ram",
    processors: "processor",
    graphics: "graphic",
    storages: "storage",
    connectivities: "connectivity",
    qualities: "quality",
    noiseCancellation: "noise_cancellation",
    hasMicrophone: "has_microphone",
    connectionTypes: "connection_type",
    compliances: "compliance",
    refreshRates: "refresh_rate",
    responseTimes: "response_time",
    screenStands: "screen_stand",
    definitions: "definition",
    hasStool: "has_stool",
    hasVideoPackage: "has_video_package",
    hasScreen: "has_screen",
    hasPremiumTablet: "has_premium_tablet",
    colors: "color",
    seatsNumbers: "seats_number",
    heights: "height",
    hasPowerPlug: "has_power_plug",
    hasAdjustableHeight: "has_adjustable_height",
    hasCableManagement: "has_cable_management",
    hasWheels: "has_wheels",
    armrests: "armrest",
    lumbarSupports: "lumbar_support",
    dimensions: "dimensions",
    weight: "weight",
  };

  for (const filterKey in filters) {
    if (filters[filterKey] === null || filters[filterKey] === "" || (Array.isArray(filters[filterKey]) && !filters[filterKey].length)) {
      continue;
    }

    const dbKey = filterDbKey[filterKey];
    const categoriesToFilter = [];

    const getPrice = (prices) => prices.find((price) => price.currency === currentCurrency);

    switch (filterKey) {
      case "category":
        switch (filters[filterKey]) {
          case "ACCESSORY":
            categoriesToFilter.push("SCREEN", "HEADPHONES", "SPEAKERS", "KEYBOARD", "MOUSE", "ADAPTER", "CHARGER", "CASE");
            break;
          case "DEVICE":
            categoriesToFilter.push("COMPUTER", "TABLET", "SMARTPHONE");
            break;
          case "ACOUSTIC":
            categoriesToFilter.push("PHONEBOOTH");
            break;
          case "SEAT":
            categoriesToFilter.push("CHAIR");
            break;
          case "TABLE":
            categoriesToFilter.push("DESK");
            break;
          case "FURNITURE":
            categoriesToFilter.push("PHONEBOOTH", "CHAIR", "DESK");
            break;
          case "HARDWARE":
            categoriesToFilter.push("COMPUTER", "TABLET", "SMARTPHONE", "SCREEN", "HEADPHONES", "SPEAKERS", "KEYBOARD", "MOUSE", "ADAPTER", "CHARGER", "CASE");
            break;
          default:
            categoriesToFilter.push(filters[filterKey]);
            break;
        }

        if (categoriesToFilter.length === 0) {
          categoriesToFilter.push("COMPUTER", "TABLET", "SMARTPHONE", "SCREEN", "HEADPHONES", "SPEAKERS", "KEYBOARD", "MOUSE", "ADAPTER", "CHARGER", "CASE", "PHONEBOOTH", "CHAIR", "DESK");
        }

        newFilteredProductGroups = newFilteredProductGroups.filter(
          (g) => categoriesToFilter.includes(g[filterKey]),
        );
        break;

      case "search":
        newFilteredProductGroups = newFilteredProductGroups.filter((d) => productGroupMatches(d, filters.search));
        break;

      case "deliveryMaxTime":
        newFilteredProductGroups = newFilteredProductGroups.filter((g) => g.products.some((p) => p.max_shipping && parseInt(p.max_shipping, 10) <= filters.deliveryMaxTime));
        break;

      case "priceRangeMin":
        newFilteredProductGroups = newFilteredProductGroups.filter((g) => g.products.some((p) => Number(getPrice(p.prices).amount) >= filters.priceRangeMin));
        break;

      case "priceRangeMax":
        newFilteredProductGroups = newFilteredProductGroups.filter((g) => g.products.some((p) => Number(getPrice(p.prices).amount) <= filters.priceRangeMax));
        break;

      case "weight":
        newFilteredProductGroups = filterProductsByWeight(newFilteredProductGroups, filters.weight);
        break;

      case "noiseCancellation":
      case "hasMicrophone":
      case "hasStool":
      case "hasVideoPackage":
      case "hasPremiumTablet":
      case "hasScreen":
      case "hasPowerPlug":
      case "hasAdjustableHeight":
      case "hasCableManagement":
      case "hasWheels":
        newFilteredProductGroups = newFilteredProductGroups.filter(
          (g) => g.products.some((p) => filters[filterKey] === p[dbKey]),
        );
        break;

      case "brands":
        newFilteredProductGroups = newFilteredProductGroups.filter(
          (g) => filters[filterKey].includes(g[dbKey]),
        );
        break;

      case "compliances":
      case "connectionTypes":
      case "screenStands":
        newFilteredProductGroups = newFilteredProductGroups.filter(
          (g) => g.products.some((p) => filters[filterKey].some((f) => p[dbKey]?.includes(f))),
        );
        break;

      default:
        newFilteredProductGroups = newFilteredProductGroups.filter(
          (g) => g.products.some((p) => filters[filterKey].includes(p[dbKey])),
        );
        break;
    }
  }

  return newFilteredProductGroups;
};

const productSpecs = {
  COMPUTER: [
    {
      label: "Système d'exploitation",
      key: "os_platform",
      translate: true,
      select: true,
      multiple: false,
    },
    {
      label: "RAM",
      key: "ram",
      translate: true,
      multiple: false,
    },
    {
      label: "Stockage",
      key: "storage",
      translate: true,
      multiple: false,
    },
    {
      label: "Processeur",
      key: "processor",
      translate: false,
      multiple: false,
    },
    {
      label: "Taille d'écran",
      key: "screen_size",
      translate: false,
      multiple: false,
    },
    {
      label: "Carte graphique",
      key: "graphic",
      translate: true,
      multiple: false,
    },
    {
      label: "Type de clavier",
      key: "keyboard_layout",
      translate: true,
      select: true,
    },
  ],
  SMARTPHONE: [
    {
      label: "Système d'exploitation",
      key: "os_platform",
      select: true,
      translate: true,
      multiple: false,
    },
    {
      label: "RAM",
      key: "ram",
      translate: true,
      multiple: false,
    },
    {
      label: "Stockage",
      key: "storage",
      translate: true,
      multiple: false,
    },
    {
      label: "Processeur",
      key: "processor",
      translate: false,
      multiple: false,
    },
    {
      label: "Taille d'écran",
      key: "screen_size",
      translate: false,
      multiple: false,
    },
    {
      label: "Carte graphique",
      key: "graphic",
      translate: true,
      multiple: false,
    },
    {
      label: "Connectivité",
      key: "connectivity",
      translate: false,
      multiple: false,
    },
  ],
  TABLET: [
    {
      label: "Système d'exploitation",
      key: "os_platform",
      translate: true,
      multiple: false,
      select: true,
    },
    {
      label: "RAM",
      key: "ram",
      translate: true,
      multiple: false,
    },
    {
      label: "Stockage",
      key: "storage",
      translate: true,
      multiple: false,
    },
    {
      label: "Processeur",
      key: "processor",
      translate: false,
      multiple: false,
    },
    {
      label: "Taille d'écran",
      key: "screen_size",
      translate: false,
      multiple: false,
    },
    {
      label: "Carte graphique",
      key: "graphic",
      translate: true,
      multiple: false,
    },
    {
      label: "Connectivité",
      key: "connectivity",
      translate: false,
      multiple: false,
    },
  ],
  SCREEN: [
    {
      label: "Taille d'écran",
      key: "screen_size",
      translate: false,
      multiple: false,
    },
    {
      label: "Définition",
      key: "definition",
      translate: false,
      multiple: false,
    },
    {
      label: "Compatibilité",
      key: "compliance",
      translate: false,
      multiple: true,
    },
    {
      label: "Taux de rafraîchissement",
      key: "refresh_rate",
      translate: false,
      multiple: false,
    },
    {
      label: "Temps de réponse",
      key: "response_time",
      translate: false,
      multiple: false,
    },
    {
      label: "Pied",
      key: "screen_stand",
      translate: true,
      multiple: true,
    },
    {
      label: "Raccordement",
      key: "connection_type",
      translate: true,
      multiple: true,
    },
  ],
  HEADPHONES: [
    {
      label: "Type",
      key: "equipment_sub_type",
      translate: true,
      multiple: false,
    },
    {
      label: "Autonomie",
      key: "battery_life",
      translate: false,
      multiple: false,
    },
    {
      label: "Réduction de bruit",
      key: "noise_cancellation",
      translate: true,
      multiple: false,
    },
    {
      label: "Micro",
      key: "has_microphone",
      translate: true,
      multiple: false,
    },
    {
      label: "Mode de connexion",
      key: "connection_type",
      translate: true,
      multiple: true,
    },
  ],
  SPEAKERS: [
    {
      label: "Micro",
      key: "has_microphone",
      translate: true,
      multiple: false,
    },
    {
      label: "Webcam",
      key: "has_webcam",
      translate: true,
      multiple: false,
    },
    {
      label: "Optimisé pour",
      key: "optimized_to",
      translate: true,
      multiple: false,
    },
    {
      label: "Mode de connexion",
      key: "connection_type",
      translate: true,
      multiple: true,
    },
    {
      label: "Autonomie",
      key: "battery_life",
      translate: false,
      multiple: false,
    },
  ],
  KEYBOARD: [
    {
      label: "Compatibilité",
      key: "compliance",
      translate: false,
      multiple: true,
    },
    {
      label: "Mode de connexion",
      key: "connection_type",
      translate: true,
      multiple: true,
    },
  ],
  MOUSE: [
    {
      label: "Compatibilité",
      key: "compliance",
      translate: false,
      multiple: true,
    },
    {
      label: "Autonomie",
      key: "battery_life",
      translate: false,
      multiple: false,
    },
    {
      label: "Mode de connexion",
      key: "connection_type",
      translate: true,
      multiple: true,
    },
  ],
  ADAPTER: [
    {
      label: "Compatibilité",
      key: "compliance",
      translate: false,
      multiple: true,
    },
    {
      label: "Entrée",
      key: "connection_type",
      translate: true,
      multiple: true,
    },
    {
      label: "Type",
      key: "equipment_sub_type",
      translate: true,
      multiple: false,
    },
    {
      label: "Puissance",
      key: "power",
      translate: false,
      multiple: false,
    },
  ],
  CHARGER: [
    {
      label: "Compatibilité",
      key: "compliance",
      translate: false,
      multiple: true,
    },
    {
      label: "Entrée",
      key: "connection_type",
      translate: true,
      multiple: true,
    },
    {
      label: "Puissance",
      key: "power",
      translate: false,
      multiple: false,
    },
  ],
  CASE: [
    {
      label: "Taille d'écran",
      key: "screen_size",
      translate: false,
      multiple: false,
    },
    {
      label: "Compatibilité",
      key: "compliance",
      translate: false,
      multiple: true,
    },
  ],
  PHONEBOOTH: [
    {
      label: "Sièges",
      key: "has_stool",
      translate: true,
      multiple: false,
    },
    {
      label: "Pack visio",
      key: "has_video_package",
      translate: true,
      multiple: false,
    },
    {
      label: "Écran",
      key: "has_screen",
      translate: true,
      multiple: false,
    },
    {
      label: "Tablette premium",
      key: "has_premium_tablet",
      translate: true,
      multiple: false,
    },
    {
      label: "Livraison et montage",
      key: "assembly",
      translate: true,
      multiple: false,
    },
    {
      label: "Couleur",
      key: "color",
      translate: true,
      multiple: false,
    },
    {
      label: "Nombre de places",
      key: "seats_number",
      translate: false,
      multiple: false,
    },
    {
      label: "H x L x P (cm)",
      key: "dimensions",
      translate: false,
      multiple: false,
    },
  ],
  CHAIR: [
    {
      label: "Roulettes",
      key: "has_wheels",
      translate: true,
      multiple: false,
    },
    {
      label: "Accoudoirs",
      key: "armrest",
      translate: true,
      multiple: false,
    },
    {
      label: "Renfort Lombaire",
      key: "lumbar_support",
      translate: true,
      multiple: false,
    },
  ],
  DESK: [
    {
      label: "Longueur x Largeur (cm)",
      key: "dimensions",
      translate: false,
      multiple: false,
    },
    {
      label: "Hauteur (cm)",
      key: "height",
      translate: false,
      multiple: false,
    },
    {
      label: "Prises",
      key: "has_power_plug",
      translate: true,
      multiple: false,
    },
    {
      label: "Hauteur réglable",
      key: "has_adjustable_height",
      translate: true,
      multiple: false,
    },
    {
      label: "Gestion de câbles",
      key: "has_cable_management",
      translate: true,
      multiple: false,
    },
  ],
  ACOUSTIC_CHAIR: [
    {
      label: "Roulettes",
      key: "has_wheels",
      translate: true,
      multiple: false,
    },
    {
      label: "Accoudoirs",
      key: "armrest",
      translate: true,
      multiple: false,
    },
    {
      label: "Renfort Lombaire",
      key: "lumbar_support",
      translate: true,
      multiple: false,
    },

  ],
};

const productFiltersList = [
  {
    title: "Marques",
    key: "brands",
    type: "select",
    categories: ["COMPUTER", "TABLET", "SMARTPHONE", "SCREEN", "HEADPHONES", "KEYBOARD", "MOUSE", "ADAPTER", "SPEAKERS", "CHARGER", "CASE", "PHONEBOOTH", "CHAIR", "DESK"],
    options: (category) => {
      switch (category) {
        case "COMPUTER":
          return [
            { label: "Apple", value: "apple", translate: false },
            { label: "Microsoft", value: "microsoft", translate: false },
            { label: "Lenovo", value: "lenovo", translate: false },
            { label: "Dell", value: "dell", translate: false },
            { label: "Acer Chromebook", value: "acer-chromebook", translate: false },
            { label: "HP", value: "hp", translate: false },
            { label: "Asus", value: "asus", translate: false },
          ];
        case "TABLET":
          return [
            { label: "Apple", value: "apple", translate: false },
            { label: "Microsoft", value: "microsoft", translate: false },
          ];
        case "SMARTPHONE":
          return [
            { label: "Apple", value: "apple", translate: false },
            { label: "Samsung", value: "samsung", translate: false },
            { label: "Google", value: "google", translate: false },
            { label: "Xiaomi", value: "xiaomi", translate: false },
          ];
        case "SCREEN":
          return [
            { label: "Apple", value: "apple", translate: false },
            { label: "EIZO", value: "eizo", translate: false },
            { label: "Samsung", value: "samsung", translate: false },
            { label: "Lenovo", value: "lenovo", translate: false },
            { label: "NEC", value: "nec", translate: false },
            { label: "iiyama", value: "iiyama", translate: false },
          ];
        case "HEADPHONES":
          return [
            { label: "Apple", value: "apple", translate: false },
            { label: "Jabra", value: "jabra", translate: false },
            { label: "Sony", value: "sony", translate: false },
            { label: "Elgato", value: "elgato", translate: false },
          ];
        case "KEYBOARD":
          return [
            { label: "Apple", value: "apple", translate: false },
            { label: "Cherry", value: "cherry", translate: false },
            { label: "Logitech", value: "logitech", translate: false },
            { label: "Razer", value: "razer", translate: false },
            { label: "Work Louder", value: "work-louder", translate: false },
          ];
        case "MOUSE":
          return [
            { label: "Apple", value: "apple", translate: false },
            { label: "Cherry", value: "cherry", translate: false },
            { label: "Logitech", value: "logitech", translate: false },
            { label: "Razer", value: "razer", translate: false },
          ];
        case "SPEAKERS":
          return [
            { label: "Jabra", value: "jabra", translate: false },
            { label: "Devialet", value: "devialet", translate: false },
            { label: "Logitech", value: "logitech", translate: false },
            { label: "Yealink", value: "yealink", translate: false },
          ];
        case "ADAPTER":
          return [
            { label: "Apple", value: "apple", translate: false },
            { label: "Belkin", value: "belkin", translate: false },
          ];
        case "CHARGER":
          return [
            { label: "Apple", value: "apple", translate: false },
            { label: "Belkin", value: "belkin", translate: false },
          ];
        case "PHONEBOOTH":
          return [
            { label: "Persy Booths", value: "persy booths", translate: false },
            { label: "Leet Design", value: "leet design", translate: false },
            { label: "Work With Island", value: "work-with-island", translate: false },
            { label: "Offcoustic", value: "offcoustic", translate: false },
          ];
        case "CHAIR":
          return [
            { label: "Slean", value: "slean", translate: false },
            { label: "Herman Miller", value: "herman-miller", translate: false },
          ];
        case "DESK":
          return [
            { label: "Slean", value: "slean", translate: false },
          ];
        default:
          return [];
      }
    },
  },
  {
    title: "Prix",
    key: "priceRange",
    type: "range",
    categories: ["COMPUTER", "TABLET", "SMARTPHONE", "SCREEN", "HEADPHONES", "KEYBOARD", "MOUSE", "ADAPTER", "SPEAKERS", "CHARGER", "CASE", "PHONEBOOTH", "CHAIR", "DESK"],
  },
  {
    name: "storage",
    title: "Capacité de stockage (Go)",
    key: "storages",
    type: "select",
    categories: ["COMPUTER", "TABLET", "SMARTPHONE"],
    options: (category) => {
      switch (category) {
        case "COMPUTER":
          return [
            { label: "128 Go", value: "128 Go", translate: true },
            { label: "256 Go", value: "256 Go", translate: true },
            { label: "512 Go", value: "512 Go", translate: true },
            { label: "1 To", value: "1 To", translate: true },
            { label: "2 To", value: "2 To", translate: true },
            { label: "4 To", value: "4 To", translate: true },
            { label: "8 To", value: "8 To", translate: true },
          ];
        case "SMARTPHONE":
        case "TABLET":
          return [
            { label: "64 Go", value: "64 Go", translate: true },
            { label: "128 Go", value: "128 Go", translate: true },
            { label: "256 Go", value: "256 Go", translate: true },
            { label: "512 Go", value: "512 Go", translate: true },
            { label: "2 To", value: "2 To", translate: true },
          ];
        default:
          return [];
      }
    },
  },
  {
    name: "equipment_sub_type",
    title: "Type",
    key: "types",
    type: "select",
    categories: ["HEADPHONES", "ADAPTER"],
    options: (category) => {
      switch (category) {
        case "HEADPHONES":
          return [
            { label: "Casque", value: "HEADPHONES", translate: true },
            { label: "Écouteurs", value: "EARPHONES", translate: true },
          ];
        case "ADAPTER":
          return [
            { label: "Câble", value: "CABLE", translate: true },
            { label: "Adaptateur", value: "ADAPTER", translate: true },
            { label: "Prise secteur", value: "POWER_PLUG", translate: true },
          ];
        default:
          return [];
      }
    },
  },
  {
    title: "Délai de livraison",
    key: "deliveryMaxTime",
    type: "select",
    categories: ["COMPUTER", "TABLET", "SMARTPHONE", "SCREEN", "HEADPHONES", "KEYBOARD", "MOUSE", "ADAPTER", "SPEAKERS", "CHARGER", "CASE"],
    options: () => [
      { label: "Moins de 4 jours", value: 4, translate: true },
      { label: "Moins de 6 jours", value: 6, translate: true },
      { label: "Moins de 7 jours", value: 7, translate: true },
    ],
  },
  {
    name: "screen_size",
    title: "Taille écran (pouces)",
    key: "screenSizes",
    type: "select",
    categories: ["COMPUTER", "TABLET", "SMARTPHONE", "SCREEN", "CASE"],
    options: (category) => {
      switch (category) {
        case "COMPUTER":
          return [
            { label: "13\"", value: "13\"", translate: false },
            { label: "13.3\"", value: "13.3\"", translate: false },
            { label: "13.5\"", value: "13.5\"", translate: false },
            { label: "14\"", value: "14\"", translate: false },
            { label: "14.4\"", value: "14.4\"", translate: false },
            { label: "15\"", value: "15\"", translate: false },
            { label: "15.6\"", value: "15.6\"", translate: false },
            { label: "16\"", value: "16\"", translate: false },
            { label: "24\"", value: "24\"", translate: false },
            { label: "27\"", value: "27\"", translate: false },
          ];
        case "TABLET":
          return [
            { label: "10.2\"", value: "10.2\"", translate: false },
            { label: "10.9\"", value: "10.9\"", translate: false },
            { label: "11\"", value: "11\"", translate: false },
            { label: "12.3\"", value: "12.3\"", translate: false },
            { label: "12.9\"", value: "12.9\"", translate: false },
            { label: "13\"", value: "13\"", translate: false },
          ];
        case "SMARTPHONE":
          return [
            { label: "4.7\"", value: "4.7\"", translate: false },
            { label: "6.1\"", value: "6.1\"", translate: false },
            { label: "6.3\"", value: "6.3\"", translate: false },
            { label: "6.4\"", value: "6.4\"", translate: false },
            { label: "6.5\"", value: "6.5\"", translate: false },
            { label: "6.7\"", value: "6.7\"", translate: false },
            { label: "6.9\"", value: "6.9\"", translate: false },
          ];
        case "SCREEN":
          return [
            { label: "22\"", value: "22\"", translate: false },
            { label: "23\"", value: "23\"", translate: false },
            { label: "24\"", value: "24\"", translate: false },
            { label: "27\"", value: "27\"", translate: false },
            { label: "43\"", value: "43\"", translate: false },
            { label: "55\"", value: "55\"", translate: false },
          ];
        case "CASE":
          return [
            { label: "10.5\"", value: "10.5\"", translate: false },
            { label: "11\"", value: "11\"", translate: false },
            { label: "12.9\"", value: "12.9\"", translate: false },
            { label: "13\"", value: "13\"", translate: false },
            { label: "14\"", value: "14\"", translate: false },
            { label: "16\"", value: "16\"", translate: false },
          ];
        default:
          return [];
      }
    },
  },
  {
    name: "ram",
    title: "RAM (Go)",
    key: "memories",
    type: "select",
    categories: ["COMPUTER", "TABLET", "SMARTPHONE"],
    options: (category) => {
      switch (category) {
        case "COMPUTER":
          return [
            { label: "8 Go", value: "8 Go", translate: true },
            { label: "16 Go", value: "16 Go", translate: true },
            { label: "24 Go", value: "24 Go", translate: true },
            { label: "32 Go", value: "32 Go", translate: true },
            { label: "64 Go", value: "64 Go", translate: true },
            { label: "96 Go", value: "96 Go", translate: true },
            { label: "128 Go", value: "128 Go", translate: true },
          ];
        case "TABLET":
          return [
            { label: "6 Go", value: "6 Go", translate: true },
            { label: "8 Go", value: "8 Go", translate: true },
            { label: "16 Go", value: "16 Go", translate: true },
          ];
        case "SMARTPHONE":
          return [
            { label: "4 Go", value: "4 Go", translate: true },
            { label: "6 Go", value: "6 Go", translate: true },
            { label: "8 Go", value: "8 Go", translate: true },
          ];
        default:
          return [];
      }
    },
  },
  {
    name: "processor",
    title: "Type de processeur",
    key: "processors",
    type: "select",
    categories: ["COMPUTER", "TABLET", "SMARTPHONE"],
    options: (category) => {
      switch (category) {
        case "COMPUTER":
          return [
            { label: "i5", value: "i5", translate: false },
            { label: "i7", value: "i7", translate: false },
            { label: "i9", value: "i9", translate: false },
            { label: "M1", value: "M1", translate: false },
            { label: "M1 Pro", value: "M1 Pro", translate: false },
            { label: "M1 Max", value: "M1 Max", translate: false },
            { label: "M2", value: "M2", translate: false },
            { label: "M2 Pro", value: "M2 Pro", translate: false },
            { label: "M2 Max", value: "M2 Max", translate: false },
            { label: "M3", value: "M3", translate: false },
            { label: "M3 Pro", value: "M3 Pro", translate: false },
            { label: "M3 Max", value: "M3 Max", translate: false },
            { label: "M4", value: "M4", translate: false },
            { label: "AMD Ryzen", value: "AMD Ryzen", translate: false },
            { label: "AMD Ryzen™ 5", value: "AMD Ryzen™ 5", translate: false },
            { label: "AMD Ryzen™ 7", value: "AMD Ryzen™ 7", translate: false },
            { label: "Core Ultra 5", value: "Core Ultra 5", translate: false },
            { label: "Core Ultra 7", value: "Core Ultra 7", translate: false },
            { label: "Core Ultra 9", value: "Core Ultra 9", translate: false },
            { label: "Snapdragon X Plus", value: "Snapdragon X Plus", translate: false },
            { label: "Snapdragon X Elite", value: "Snapdragon X Elite", translate: false },
          ];
        case "TABLET":
          return [
            { label: "i5", value: "i5", translate: false },
            { label: "i7", value: "i7", translate: false },
            { label: "A14 Bionic", value: "A14 Bionic", translate: false },
            { label: "M1", value: "M1", translate: false },
            { label: "M2", value: "M2", translate: false },
            { label: "M4", value: "M4", translate: false },
            { label: "Core Ultra 5", value: "Core Ultra 5", translate: false },
            { label: "Core Ultra 7", value: "Core Ultra 7", translate: false },
            { label: "Core Ultra 9", value: "Core Ultra 9", translate: false },
          ];
        case "SMARTPHONE":
          return [
            { label: "A13 Bionic", value: "A13 Bionic", translate: false },
            { label: "A14 Bionic", value: "A14 Bionic", translate: false },
            { label: "A15 Bionic", value: "A15 Bionic", translate: false },
            { label: "A16 Bionic", value: "A16 Bionic", translate: false },
            { label: "A17 Pro", value: "A17 Pro", translate: false },
            { label: "A18", value: "A18", translate: false },
            { label: "A18 Pro", value: "A18 Pro", translate: false },
            { label: "Exynos 1280", value: "Exynos 1280", translate: false },
            { label: "Snapdragon", value: "Snapdragon", translate: false },
            { label: "Tensor G2", value: "Tensor G2", translate: false },
            { label: "M4", value: "M4", translate: false },
            { label: "MediaTek Helio G88", value: "MediaTek Helio G88", translate: false },
            { label: "Core Ultra 5", value: "Core Ultra 5", translate: false },
            { label: "Core Ultra 7", value: "Core Ultra 7", translate: false },
            { label: "Core Ultra 9", value: "Core Ultra 9", translate: false },
          ];
        default:
          return [];
      }
    },
  },
  {
    name: "graphic",
    title: "Carte graphique",
    key: "graphics",
    type: "select",
    categories: ["COMPUTER", "TABLET"],
    options: (category) => {
      switch (category) {
        case "COMPUTER":
          return [
            { label: "Intégrée", value: "Intégrée", translate: true },
            { label: "Intel® UHD", value: "Intel® UHD", translate: false },
            { label: "AMD Radeon", value: "AMD Radeon", translate: false },
            { label: "Intel® Iris®", value: "Intel® Iris®", translate: false },
            { label: "Intel® Iris® Xe", value: "Intel® Iris® Xe", translate: false },
            { label: "NVIDIA® GeForce RTX™ 3050 Ti avec 4 Go", value: "NVIDIA® GeForce RTX™ 3050 Ti avec 4 Go", translate: true },
            { label: "NVIDIA Quadro® T500 4 Go GDDR7", value: "NVIDIA Quadro® T500 4 Go GDDR7", translate: false },
            { label: "NVIDIA® RTX™ A500 4 Go GDDR6", value: "NVIDIA® RTX™ A500 4 Go GDDR6", translate: false },
            { label: "NVIDIA RTX™ 2000 Ada Generation Notebook-GPU 8GB GDDR6", value: "NVIDIA RTX™ 2000 Ada Generation Notebook-GPU 8GB GDDR6", translate: false },
            { label: "Nvidia GeForce RTX 4080", value: "Nvidia GeForce RTX 4080", translate: false },
            { label: "Nvidia GeForce RTX 4070 ", value: "Nvidia GeForce RTX 4070", translate: false },
            { label: "Nvidia GeForce RTX 4060", value: "Nvidia GeForce RTX 4060", translate: false },
            { label: "GPU 8 coeurs", value: "GPU 8 coeurs", translate: true },
            { label: "GPU 10 coeurs", value: "GPU 10 coeurs", translate: true },
            { label: "GPU 14 coeurs", value: "GPU 14 coeurs", translate: true },
            { label: "GPU 16 coeurs", value: "GPU 16 coeurs", translate: true },
            { label: "GPU 18 coeurs", value: "GPU 18 coeurs", translate: true },
            { label: "GPU 19 coeurs", value: "GPU 19 coeurs", translate: true },
            { label: "GPU 24 coeurs", value: "GPU 24 coeurs", translate: true },
            { label: "GPU 30 coeurs", value: "GPU 30 coeurs", translate: true },
            { label: "GPU 32 coeurs", value: "GPU 32 coeurs", translate: true },
            { label: "GPU 38 coeurs", value: "GPU 38 coeurs", translate: true },
            { label: "GPU 40 coeurs", value: "GPU 40 coeurs", translate: true },
            { label: "Qualcomm Adreno", value: "Qualcomm Adreno", translate: false },
          ];
        case "TABLET":
          return [
            { label: "Intégrée", value: "Intégrée", translate: true },
            { label: "Intel® Iris® Xe", value: "Intel® Iris® Xe", translate: false },
          ];
        default:
          return [];
      }
    },
  },
  {
    name: "connectivity",
    title: "Connectivité",
    key: "connectivities",
    type: "select",
    categories: ["TABLET", "SMARTPHONE"],
    options: (category) => {
      switch (category) {
        case "TABLET":
          return [
            { label: "WiFi", value: "WiFi", translate: false },
            { label: "WiFi + 4G", value: "WiFi + 4G", translate: false },
            { label: "WiFi + 5G", value: "WiFi + 5G", translate: false },
          ];
        case "SMARTPHONE":
          return [
            { label: "4G", value: "4G", translate: false },
            { label: "5G", value: "5G", translate: false },
          ];
        default:
          return [];
      }
    },
  },
  {
    title: "Poids (g)",
    key: "weight",
    type: "select",
    categories: ["COMPUTER"],
    options: () => [
      { label: "Moins de 1 kg", value: "feather", translate: true },
      { label: "Entre 1,1 kg et 2 kg", value: "light", translate: true },
      { label: "Entre 2,1 kg et 2,5 kg", value: "medium", translate: true },
      { label: "Plus de 2,5 kg", value: "heavy", translate: true },
    ],
  },
  {
    name: "typology",
    title: "Typologie",
    key: "qualities",
    type: "select",
    categories: ["COMPUTER", "TABLET", "SMARTPHONE", "SCREEN", "HEADPHONES", "KEYBOARD", "MOUSE", "ADAPTER", "SPEAKERS", "CHARGER", "CASE"],
    options: () => [
      { label: "Neuf", value: "new", translate: true },
      { label: "Reconditionné +", value: "refurb", translate: true },
    ],
  },
  {
    name: "noise_cancellation",
    title: "Réduction de bruit active",
    key: "noiseCancellation",
    type: "select",
    categories: ["HEADPHONES"],
    options: () => [
      { label: "Oui", value: true, translate: true },
      { label: "Non", value: false, translate: true },
    ],
  },
  {
    name: "has_microphone",
    title: "Microphone",
    key: "hasMicrophone",
    type: "select",
    categories: ["HEADPHONES", "SPEAKERS"],
    options: () => [
      { label: "Oui", value: true, translate: true },
      { label: "Non", value: false, translate: true },
    ],
  },
  {
    name: "connection_type",
    title: "Type de connexion",
    key: "connectionTypes",
    type: "select",
    categories: ["MOUSE", "KEYBOARD", "CHARGER", "ADAPTER", "HEADPHONES", "SPEAKERS"],
    options: (category) => {
      switch (category) {
        case "CHARGER":
        case "ADAPTER":
          return [
            { label: "USB-C", value: "usb-c", translate: false },
          ];
        case "MOUSE":
        case "KEYBOARD":
          return [
            { label: "Sans fil", value: "wireless", translate: true },
          ];
        case "HEADPHONES":
        case "SPEAKERS":
          return [
            { label: "Sans fil", value: "wireless", translate: true },
            { label: "USB", value: "usb", translate: false },
            { label: "USB-C", value: "usb-c", translate: false },
            { label: "Jack", value: "jack", translate: false },
            { label: "Ethernet", value: "ethernet", translate: false },
            { label: "DisplayPort", value: "displayport", translate: false },

          ];
        default:
          return [];
      }
    },
  },
  {
    name: "compliance",
    title: "Compatibilité",
    key: "compliances",
    type: "select",
    categories: ["SCREEN", "HEADPHONES", "KEYBOARD", "MOUSE", "ADAPTER", "SPEAKERS", "CHARGER", "CASE"],
    options: () => [
      { label: "Mac", value: "mac", translate: false },
      { label: "iPad", value: "ipad", translate: false },
      { label: "PC", value: "pc", translate: false },
      { label: "Tablette", value: "tablet", translate: true },
      { label: "Téléphone", value: "smartphone", translate: true },
    ],
  },
  {
    name: "refresh_rate",
    title: "Taux de rafraîchissement",
    key: "refreshRates",
    type: "select",
    categories: ["SCREEN"],
    options: () => [
      { label: "60Hz", value: "60Hz", translate: false },
      { label: "75Hz", value: "75Hz", translate: false },
    ],
  },
  {
    name: "response_time",
    title: "Temps de réponse",
    key: "responseTimes",
    type: "select",
    categories: ["SCREEN"],
    options: () => [
      { label: "1 ms", value: "1 ms", translate: false },
      { label: "2 ms", value: "2 ms", translate: false },
      { label: "3 ms", value: "3 ms", translate: false },
      { label: "4 ms", value: "4 ms", translate: false },
      { label: "5 ms", value: "5 ms", translate: false },
      { label: "6 ms", value: "6 ms", translate: false },
      { label: "7 ms", value: "7 ms", translate: false },
    ],
  },
  {
    name: "screen_stand",
    title: "Pied",
    key: "screenStands",
    type: "select",
    categories: ["SCREEN"],
    options: () => [
      { label: "Pivotable", value: "rotatable", translate: true },
      { label: "Réglable", value: "adjustable", translate: true },
    ],
  },
  {
    name: "definition",
    title: "Définition",
    key: "definitions",
    type: "select",
    categories: ["SCREEN"],
    options: () => [
      { label: "HD", value: "HD", translate: false },
      { label: "Full HD", value: "Full HD", translate: false },
      { label: "Quad HD", value: "Quad HD", translate: false },
      { label: "4K", value: "4K", translate: false },
      { label: "5K", value: "5K", translate: false },
    ],
  },
  {
    name: "seats_number",
    title: "Capacité",
    key: "seatsNumbers",
    type: "select",
    categories: ["PHONEBOOTH"],
    options: () => [
      { label: "1", value: "1", translate: false },
      { label: "2", value: "2", translate: false },
      { label: "4", value: "4", translate: false },
    ],
  },
  {
    name: "has_stool",
    title: "Tabouret",
    key: "hasStool",
    type: "select",
    categories: ["PHONEBOOTH"],
    options: () => [
      { label: "Oui", value: true, translate: true },
      { label: "Non", value: false, translate: true },
    ],
  },
  {
    name: "has_video_package",
    title: "Pack visio",
    key: "hasVideoPackage",
    type: "select",
    categories: ["PHONEBOOTH"],
    options: () => [
      { label: "Oui", value: true, translate: true },
      { label: "Non", value: false, translate: true },
    ],
  },
  {
    name: "has_premium_tablet",
    title: "Tablette premium",
    key: "hasPremiumTablet",
    type: "select",
    categories: ["PHONEBOOTH"],
    options: () => [
      { label: "Oui", value: true, translate: true },
      { label: "Non", value: false, translate: true },
    ],
  },
  {
    name: "has_screen",
    title: "Écran",
    key: "hasScreen",
    type: "select",
    categories: ["PHONEBOOTH"],
    options: () => [
      { label: "Oui", value: true, translate: true },
      { label: "Non", value: false, translate: true },
    ],
  },
  {
    name: "has_wheels",
    title: "Roulettes",
    key: "hasWheels",
    type: "select",
    categories: ["CHAIR", "ACOUSTIC_CHAIR"],
    options: () => [
      { label: "Oui", value: true, translate: true },
      { label: "Non", value: false, translate: true },
    ],
  },
  {
    name: "armrest",
    title: "Accoudoirs",
    key: "armrests",
    type: "select",
    categories: ["CHAIR", "ACOUSTIC_CHAIR"],
    options: () => [
      { label: "Aucun", value: "none", translate: true },
      { label: "Réglable", value: "adjustable", translate: true },
      { label: "Fixe", value: "fixed", translate: true },
    ],
  },
  {
    name: "lumbar_support",
    title: "Renfort Lombaire",
    key: "lumbarSupports",
    type: "select",
    categories: ["CHAIR", "ACOUSTIC_CHAIR"],
    options: () => [
      { label: "Dossier classique", value: "classic", translate: true },
      { label: "Renfort Lombaire", value: "lumbar-support", translate: true },
      { label: "Renfort Lombaire Réglable", value: "lumbar-support-adjustable", translate: true },
    ],
  },
  {
    name: "dimension",
    title: "Dimensions",
    key: "dimensions",
    type: "select",
    categories: ["DESK"],
    options: () => [
      { label: "60 x 45", value: "60 x 45", translate: false },
      { label: "75 x 45", value: "75 x 45", translate: false },
      { label: "100 x 60", value: "100 x 60", translate: false },
      { label: "120 x 60", value: "120 x 60", translate: false },
      { label: "120 x 130", value: "120 x 130", translate: false },
      { label: "131 x 70", value: "131 x 70", translate: false },
      { label: "160 x 80", value: "160 x 80", translate: false },
      { label: "220 x 80", value: "220 x 80", translate: false },
      { label: "220 x 100", value: "220 x 100", translate: false },
    ],
  },
  {
    name: "height",
    title: "Hauteur",
    key: "heights",
    type: "select",
    categories: ["DESK"],
    options: () => [
      { label: "73", value: "73", translate: false },
      { label: "74", value: "74", translate: false },
      { label: "105", value: "105", translate: false },
      { label: "63 / 127", value: "63 / 127", translate: false },
    ],
  },
  {
    name: "has_power_plug",
    title: "Prises",
    key: "hasPowerPlug",
    type: "select",
    categories: ["DESK"],
    options: () => [
      { label: "Oui", value: true, translate: true },
      { label: "Non", value: false, translate: true },
    ],
  },
  {
    name: "has_adjustable_height",
    title: "Hauteur Réglable",
    key: "hasAdjustableHeight",
    type: "select",
    categories: ["DESK"],
    options: () => [
      { label: "Oui", value: true, translate: true },
      { label: "Non", value: false, translate: true },
    ],
  },
  {
    name: "has_cable_management",
    title: "Gestion de câbles",
    key: "hasCableManagement",
    type: "select",
    categories: ["DESK"],
    options: () => [
      { label: "Oui", value: true, translate: true },
      { label: "Non", value: false, translate: true },
    ],
  },
];

const getSpecValues = (specKey, category) => {
  const specFilter = productFiltersList.find((filter) => filter.name === specKey);

  if (specFilter && specFilter.categories.includes(category)) {
    return specFilter.options(category);
  }

  return null;
};

const specValueLabels = {
  adjustable: "Réglable",
  rotatable: "Pivotable",
  wireless: "Sans fil",
  usb: "USB",
  jack: "Jack",
  vga: "VGA",
  ethernet: "Ethernet",
  displayport: "DisplayPort",
  mac: "Mac",
  pc: "PC",
  hdmi: "HDMI",
  ipad: "iPad",
  tablet: "Tablette",
  smartphone: "Smartphone",
  "usb-c": "USB-C",
  true: "Oui",
  false: "Non",
  HEADPHONES: "Casque",
  EARPHONES: "Écouteurs",
  CABLE: "Câble",
  ADAPTER: "Adaptateur",
  POWER_PLUG: "Prise secteur",
  "heather gray": "Gris chiné",
  grey: "Gris",
  black: "Noir",
  white: "Blanc",
  "not-included": "Non inclus",
  included: "Inclus",
  none: "Aucun",
  fixed: "Fixe",
  classic: "Dossier classique",
  "lumbar-support": "Renfort Lombaire",
  "lumbar-support-adjustable": "Renfort Lombaire réglable",
  macos: "MacOS",
  linux: "Linux",
  windows: "Windows",
  android: "Android",
  ipados: "iPadOS",
  ios: "iOS",
};

const getSpecificationValueLabel = (specKey, specValue) => {
  if (specKey === "optimized_to") {
    return `${specValue} personnes`;
  }

  if (specKey === "armrest" && specValue !== "none") {
    return `Accoudoir ${specValueLabels[specValue]}`;
  }

  if (Array.isArray(specValue)) {
    if (specValue.length) {
      return specValue.map((v) => specValueLabels[v]).join(" / ");
    }

    return null;
  }

  if (specValueLabels[specValue]) {
    return specValueLabels[specValue];
  }

  return specValue;
};

const categoryGroups = [
  {
    name: "device",
    universe: "hardware",
    categories: ["COMPUTER", "TABLET", "SMARTPHONE"],
  },
  {
    name: "accessory",
    universe: "hardware",
    categories: ["SCREEN", "KEYBOARD", "MOUSE", "HEADPHONES", "SPEAKERS", "ADAPTER", "CHARGER", "CASE"],
  },
  {
    name: "acoustic",
    universe: "furniture",
    categories: ["PHONEBOOTH", "ACOUSTIC_CHAIR"],
  },
  {
    name: "seat",
    universe: "furniture",
    categories: ["CHAIR"],
  },
  {
    name: "table",
    universe: "furniture",
    categories: ["DESK"],
  },
];

const categoriesList = {
  COMPUTER:
  {
    title: "Ordinateur",
    value: "COMPUTER",
  },
  TABLET:
  {
    title: "Tablette",
    value: "TABLET",
  },
  SMARTPHONE:
  {
    title: "Smartphone",
    value: "SMARTPHONE",
  },

  SCREEN:
  {
    title: "Écran & TV",
    value: "SCREEN",
  },
  HEADPHONES:
  {
    title: "Casque audio",
    value: "HEADPHONES",
  },
  KEYBOARD:
  {
    title: "Clavier",
    value: "KEYBOARD",
  },
  MOUSE:
  {
    title: "Souris",
    value: "MOUSE",
  },
  ADAPTER:
  {
    title: "Adaptateur",
    value: "ADAPTER",
  },
  CHAIR:
  {
    title: "Chaise",
    value: "CHAIR",
  },
  DESK:
  {
    title: "Bureau",
    value: "DESK",
  },
  ACOUSTIC_CHAIR:
  {
    title: "Chaise Acoustique",
    value: "ACOUSTIC_CHAIR",
  },
  PHONEBOOTH:
  {
    title: "Cabine Acoustique",
    value: "PHONEBOOTH",
  },
  OTHER:
  {
    title: "Autre",
    value: "OTHER",
  },
};

const whatTypeIsThisCategory = (deviceCategory) => {
  for (const type of categoryGroups) {
    if (type.categories.includes(deviceCategory)) {
      return type.name;
    }
  }

  return null;
};

const findTheRightSpec = (specKey, device) => productSpecs[device.category]?.find((spec) => spec.key === specKey);

export {
  filterProductGroups,
  productSpecs,
  categoryGroups,
  getSpecificationValueLabel,
  productFiltersList,
  getSpecValues,
  categoriesList,
  whatTypeIsThisCategory,
  findTheRightSpec,
  filterOSValuesByDeviceCategory,
};

import React from "react";
import { Toast } from "tarmac-v3";

const ToastContainer = (props) => {
  const { toast, closeToast } = props;

  return (
    <Toast
      open
      key={toast.uid}
      data-cy="toast"
      severity={toast.severity}
      onClose={() => closeToast(toast.uid)}
      autoHideDuration={3000}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    >
      {toast.message}
    </Toast>
  );
};

export default ToastContainer;

import React from "react";
import { useTranslation } from "react-i18next";
import { productFiltersList } from "src/tools/ProductHelpers";

import { Layout, Text } from "tarmac-v3";
import CatalogSelectFilter from "./CatalogSelectFilter";
import CatalogRangeFilter from "./CatalogRangeFilter";

const CatalogFilters = (props) => {
  const {
    category, filters, onChangeFilters, resetFilter,
  } = props;
  const { t } = useTranslation();

  return (
    <Layout direction="column" spacing={4} flex="0 0 216px" data-cy="catalogFiltersContainer">
      {productFiltersList
        .filter((f) => f.categories.includes(category.name))
        .map((filter) => (
          <Layout direction="column" spacing={2}>
            <Text variant="body2">{t(filter.title)}</Text>

            {filter.type === "select" && (
              <CatalogSelectFilter
                filter={filter}
                category={category}
                filters={filters}
                onChangeFilters={onChangeFilters}
                resetFilter={resetFilter}
              />
            )}

            {filter.type === "range" && (
              <CatalogRangeFilter
                filter={filter}
                filters={filters}
                onChangeFilters={onChangeFilters}
              />
            )}
          </Layout>
        ))}
    </Layout>
  );
};

export default CatalogFilters;

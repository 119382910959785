import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
  Button, Heading, HorizontalLayout, VerticalLayout,
} from "tarmac-v2";

const NoRoute = () => {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <HorizontalLayout
      fullWidth
      fullHeight
      justifyContent="center"
      alignItems="center"
    >
      <VerticalLayout
        gap={24}
        fullHeight
        justifyContent="center"
        alignItems="center"
      >
        <Heading>
          {t("La page que vous recherchez n’a pas été trouvée")}
        </Heading>

        <Button onClick={() => history.push("/launchpad")}>
          {t("Retour à l'accueil")}
        </Button>

        <img loading="lazy" src="/images/website/yogi.png" alt="Contactez-nous" />
      </VerticalLayout>
    </HorizontalLayout>
  );
};

export default NoRoute;

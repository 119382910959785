import React from "react";
import { Layout, Tag } from "tarmac-v3";
import { faEllipsisV } from "@fortawesome/pro-solid-svg-icons";

const TagSelect = ({
  tagsList,
  onSelectTag,
  toggleMenu,
  setTagInfo,
  isEditable,
}) => (
  <Layout direction="row" spacing={1} flexWrap="wrap" data-cy="Tags">
    {tagsList.map((tag) => (
      <Tag
        label={tag.label}
        backgroundColor={tag.color}
        variant="filled"
        size="small"
        onClick={() => onSelectTag(tag)}
        closeIcon={isEditable}
        icon={faEllipsisV}
        onIconClick={() => {
          setTagInfo(tag);
          toggleMenu();
        }}
      />
    ))}
  </Layout>
);

export default TagSelect;

import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

import useEmployeeFilters from "src/hooks/useEmployeeFilters";
import { useUserContext } from "./UserContext";
import { useToastContext } from "./ToastContext";

const Context = createContext({});

export const useEmployeeListContext = () => useContext(Context);

export const EmployeeListProvider = ({ children }) => {
  const { t } = useTranslation();
  const { user } = useUserContext();
  const { addToast } = useToastContext();
  const [employees, setEmployees] = useState([]);
  const [employeesFiltered, setEmployeesFiltered] = useState([]);
  const [loading, setLoading] = useState(false);
  const {
    filters,
    sortModel,
    onChangeFilters,
    onChangeSortModel,
    applyFilters,
    applySort,
    resetFilters,
    setFilters,
  } = useEmployeeFilters();

  const fetchEmployees = useCallback(async () => {
    try {
      setLoading(true);
      const employeeFetched = await user.api.getUsers();

      setEmployees(employeeFetched.data);
      setEmployeesFiltered(employeeFetched.data);
      setLoading(false);
    } catch (err) {
      addToast(t("Une erreur est survenue lors du chargement de la liste."));
      setLoading(false);
    }
  }, [user, addToast, t]);

  useEffect(() => {
    if (user.isAdmin()) {
      fetchEmployees();
    }
  }, []);

  useEffect(() => {
    const newFilteredEmployees = applyFilters(employees);
    const newFilteredAndSortedEmployees = applySort(newFilteredEmployees);

    setEmployeesFiltered(newFilteredAndSortedEmployees);
  }, [filters, sortModel, employees]);

  const resetEmployeesFilteredAndFilters = useCallback(() => {
    resetFilters();
    onChangeSortModel("date", "desc");
  }, [resetFilters, onChangeSortModel]);

  const updateEmployeeInList = useCallback((newEmployee) => {
    const newEmployees = employees.map((employee) => {
      if (employee.id === newEmployee.id) {
        return newEmployee;
      }

      return employee;
    });

    setEmployees(newEmployees);
  }, [employees]);

  const values = useMemo(
    () => ({
      employees,
      employeesFiltered,
      loading,
      filters,
      sortModel,
      onChangeFilters,
      onChangeSortModel,
      reloadEmployeesList: fetchEmployees,
      updateEmployeeInList,
      resetEmployeesFilteredAndFilters,
      setFilters,
    }),
    [
      employees,
      employeesFiltered,
      loading,
      filters,
      sortModel,
      onChangeFilters,
      onChangeSortModel,
      fetchEmployees,
      updateEmployeeInList,
      resetEmployeesFilteredAndFilters,
      setFilters,
    ],
  );

  return <Context.Provider value={values}>{children}</Context.Provider>;
};
